import React from 'react';

import './events.css';

export default function Events(props) {
    return (
        <>
            <section id="section-events">
                <div className="container">
                    <div className="row event">
                        <div className="col-md-2">
                            <div className="event-date text-center">
                                <span className="event-month">Oct 8</span>
                                <span className="event-year">2020</span>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <h1 className="title">Gothoom Open Air Festival</h1>
                            Weeping Silence confirmed for Gothoom Open Air.<br /><br />
                            Bands confirmed so far:<br /><br />
                            NAPALM DEATH / ASPHYX / MARDUK / ROTTING CHRIST / GRAVEWORM / CATTLE DECAPITATION / BŐLZER / GUTALAX / HARAKIRI FOR THE SKY / MALIGNANT TUMOUR / HELEVORN / DOOMAS / DEVANGELIC / WEEPING SILENCE / DIVINE CHAOS / ATTACK OF RAGE / STEEL ENGRAVED / DAYLIGHT MISERY / INFERNO / NEVALOTH / NATURAL BORN FUCKERS / POSTHUMOUS BLASPHEMER / MORTALLY INFECTED / PERVERSITY / INFER/ BRAINSCAN / ANIME TORMENT / HOLOTROPIC /SIX DEGREES OF SEPARATION / DESIRE FOR SORROW / INFERNAL TENEBRA / R.E.T. ... and more
                        </div>
                    </div>

                    <div className="row event">
                        <div className="col-md-2">
                            <div className="event-date text-center">
                                <span className="event-month">Jul 13</span>
                                <span className="event-year">2020</span>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <h1 className="title">Gothoom Open Air Festival</h1>
                            Weeping Silence confirmed for Gothoom Open Air.<br /><br />
                            Bands confirmed so far:<br /><br />
                            NAPALM DEATH / ASPHYX / MARDUK / ROTTING CHRIST / GRAVEWORM / CATTLE DECAPITATION / BŐLZER / GUTALAX / HARAKIRI FOR THE SKY / MALIGNANT TUMOUR / HELEVORN / DOOMAS / DEVANGELIC / WEEPING SILENCE / DIVINE CHAOS / ATTACK OF RAGE / STEEL ENGRAVED / DAYLIGHT MISERY / INFERNO / NEVALOTH / NATURAL BORN FUCKERS / POSTHUMOUS BLASPHEMER / MORTALLY INFECTED / PERVERSITY / INFER/ BRAINSCAN / ANIME TORMENT / HOLOTROPIC /SIX DEGREES OF SEPARATION / DESIRE FOR SORROW / INFERNAL TENEBRA / R.E.T. ... and more
                            </div>
                    </div>
                </div>
            </section>
        </>
    )
}