import React from 'react';

import './rating.css';

export default function Rating(props) {
    const localProps = Object.assign({}, props);
    if (localProps.maxStars === undefined) {
        localProps.maxStars = 5;
    }

    if (localProps.hideRating === undefined) {
        localProps.hideRating = false;
    }

    if (localProps.rootStyles === undefined) {
        localProps.rootStyles = 'text-center';
    }

    return (
        <>
            <div className={`album-rating ${localProps.rootStyles}`}>
                {
                    Array.apply(null, {length: localProps.stars}).map((v, i) => {
                        return <i key={i} className="fas fa-star"></i>
                    })
                }

                {
                    localProps.stars < localProps.maxStars ? (
                        Array.apply(null, {length: localProps.maxStars - localProps.stars}).map((v, i) => {
                            return <i key={i} className="far fa-star"></i>
                        })
                    ) : (null)
                }

                {
                    !localProps.hideRating ? (
                        <div className="rating-text">{localProps.rating} ({localProps.ratings})</div>
                    ) : (null)
                }
            </div>
        </>
    )
}