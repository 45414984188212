import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserInfo } from '../../reducers/actions';
import apiService from '../../services/apiservice';

class Login extends React.Component {
    state = {
        successfulLogin: false,
        isWorking: false,
        form: {
            email: {
                value: '',
                valid: false,
                touched: false
            },
            password: {
                value: '',
                valid: false,
                touched: false
            }
        }
    };

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.calculateFormState = this.calculateFormState.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            isWorking: true
        });

        apiService.login().subscribe(
            rsp => {
                this.setState({
                    isWorking: false,
                    successfulLogin: true
                });

                this.props.setUserInfo(rsp.payload);
            }, error => {
                console.info(error);
                this.setState({
                    isWorking: false
                });
            }
        );
    }

    calculateFormState(form) {
        const keys = Object.keys(form);

        // Empty values
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const val = form[key].value;
            form[key].valid = val.length !== 0;
        }

        // Email validity. Simplest of regexes. Rest happens with e-mail verification.
        const emailValue = form['email'].value;
        form['email'].valid = emailValue.match(/^\S+@\S+$/) !== null;

        // Password validity.
        const passwordValue = form['password'].value;

        form['password'].valid = passwordValue.length > 8;
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const form = Object.assign({}, this.state.form);
        form[name].value = value;
        form[name].touched = true;
        this.calculateFormState(form);

        this.setState((state, props) => {
            return {
                form: form
            };
        });
    }

    render() {
        const emailInvalid = !this.state.form.email.valid && this.state.form.email.touched;
        const passwordInvalid = !this.state.form.password.valid && this.state.form.password.touched;
        const formValid = this.state.form.email.valid && this.state.form.password.valid;
        const isWorking = this.state.isWorking;
        const successfulLogin = this.state.successfulLogin;

        if (successfulLogin) {
            return (
                <Redirect to="/account" />
            )
        }

        return (
            <>
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">Sign in</a></li>
                        </ul>
                    </nav>

                    <form noValidate onSubmit={this.handleSubmit}>
                        <h1 className="title">Log in to your Bandrabbit account</h1>
                        <div className="box">
                            <div className="field">
                                <label className="label">E-mail</label>
                                <div className="form-input has-icons-left has-icons-right">
                                    <input className={`input${emailInvalid ? ' is-danger' : ''}`} type="email" placeholder="Your email address" value={this.state.form.email.value} onChange={this.handleInputChange} name="email" />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                                {
                                    emailInvalid ? (<p className="help is-danger">This email is invalid</p>) : (<></>)
                                }
                            </div>

                            <div className="field">
                                <label className="label">Your password</label>
                                <div className="form-input has-icons-left has-icons-right">
                                    <input className={`input${passwordInvalid ? ' is-danger' : ''}`} type="password" placeholder="Enter your password" value={this.state.form.password.value} onChange={this.handleInputChange} name="password" />
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </div>
                                {
                                    passwordInvalid ? (<p className="help is-danger">Your password is invalid. Make sure your password is longer than 8 characters.</p>) : (<></>)
                                }
                            </div>

                            <div className="field is-grouped">
                                <div className="form-input">
                                    {
                                        <button className="btn btn-link" disabled={isWorking || !formValid ? 'disabled' : ''}>
                                            {
                                                isWorking ? (
                                                    <>
                                                        <span>
                                                            <i className="fas fa-circle-notch fa-spin"></i>&nbsp;Logging in. Please wait.
                                                    </span>
                                                    </>
                                                ) : (
                                                        <>
                                                            Log in
                                                    </>
                                                    )
                                            }
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
};

export default connect(null, { setUserInfo })(Login);