import { USER_INFO_UPDATE, LOG_OUT } from './actions';

const initialState = {
    "loggedIn": false,
    "firstName": "",
    "lastName": "",
    "email": "",
    "subscription": {}
};

export default function userInfo(state = initialState, action) {
  switch (action.type) {
    case USER_INFO_UPDATE: {
      return Object.assign({}, action.userInfo);
    }
    case LOG_OUT: {
      return Object.assign({}, initialState);
    }
    default:
      return state;
  }
}