import React, { useState } from 'react';
import Column from 'react-columns';

import './gallery.css';

export default function Gallery(props) {

    const [image, setImage] = useState({});


    let handleKeyPress = (e) => {
        if (e.keyCode === 27) {
            setImage({});
        }
    };

    document.onkeydown = handleKeyPress;

    return (
        <>
            <section id="section-gallery">
                <div className="container gallery">
                    <Column columns={6} gap="5px">
                        {
                            props.gallery.map((img, i) => {
                                return <img key={i} src={img.url} alt="gallery" onClick={() => setImage(img)} className="img-fluid" />
                            })
                        }
                    </Column>
                </div>
                {
                    image.url === undefined ? null : (
                        <div id="br-popup" onClick={() => setImage({})}>
                                <div className="br-popup-content">
                                    <div className="card-image">
                                        <div className="image">
                                            <img src={image.url} alt="Placeholder image" />
                                        </div>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            {image.title}
                                            <br />
                                            <time dateTime="2016-1-1">{image.date}</time>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    )
                }
            </section>
        </>
    )
}