import React from 'react';
import Message from './message';
import MessageListing from './listing';
import { Route } from 'react-router-dom';

export default class Messages extends React.Component {
    render() {
        return (
            <>
                <Route exact path={this.props.match.url} component={MessageListing} />
                <Route path={`${this.props.match.url}/:messageId`} component={Message} />
            </>
        );
    }
}