import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import apiService from '../../../../services/apiservice';

export default function Post(props) {

    const postId = props.match.params.postId;
    const [post, setPost] = useState({});

    useEffect(() => {
        apiService.getPost(postId).subscribe(rsp => {
            setPost(rsp)
        });
    }, [postId]);

    if (post.id === undefined) {
        return null;
    }

    return (
        <>
            <div className="post-view">
                <div className="action-buttons">
                    <Link to="/band-studio/posts" className="btn btn-secondary btn-sm mr-1"><i className="fas fa-arrow-left"></i>&nbsp;Back to posts</Link>
                    <button className="btn btn-sm btn-danger">
                        <i className="far fa-trash-alt"></i>&nbsp;Delete
                    </button>
                </div>
                <div className="mailmessage">
                    <div className="level">
                        <div className="level-left mailmessage-title">
                            {post.title}
                            <div className="mailmessage-from">{post.from}</div>
                        </div>
                        <div className="level-left mailmessage-date">
                            {post.date}
                        </div>
                    </div>

                    <div className="content mailmessage-content is-small">
                        {post.body}
                    </div>
                </div>
            </div>
        </>
    )
}