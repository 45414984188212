import React from 'react';

export default class GenreList extends React.Component {
    render() {
        return (
            <>
                Genre list
            </>
        );
    }
}