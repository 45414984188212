import React from 'react';

export default class AccountSecurity extends React.Component {
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md-12">
                            <h1 className="section-separator">Change password</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <form>
                                <div className="field">
                                    <label className="label">Old password</label>
                                    <div className="form-input has-icons-left">
                                        <input className="form-control" type="password" placeholder="Your current password" name="oldpassword" id="oldpassword" />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-lock"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="field">
                                    <label className="label">New password</label>
                                    <div className="form-input has-icons-left">
                                        <input className="form-control" type="password" placeholder="Your desired password" name="newpassword1" id="newpassword1" />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-lock"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="field">
                                    <label className="label">Confirm new password</label>
                                    <div className="form-input has-icons-left">
                                        <input className="form-control" type="password" placeholder="Confirm your password" name="newpassword2" id="newpassword2" />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-lock"></i>
                                        </span>
                                    </div>
                                </div>

                                <div className="field is-grouped">
                                    <div className="form-input">
                                        <button className="btn btn-info">Change password</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="section-separator">Two factor authentication</h1>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}