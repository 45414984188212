import React from 'react';
import { useLocation } from 'react-router-dom';

import './footer.css';

export default function Footer() {
    let location = useLocation();
    if (location.pathname.startsWith("/events")) {
        return null;
    } else {
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2">
                                <ul>
                                    <li>
                                        <img src="/img/common/logo_inverted.png" alt="logo" />
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-2">
                                <h4 className="heading">Navigation</h4>
                                <ul>
                                    <li><a>Home</a></li>
                                    <li><a>Genres</a></li>
                                    <li><a>About</a></li>
                                    <li><a>My account</a></li>
                                    <li><a>Band studio</a></li>
                                </ul>
                            </div>
                            <div className="col-md-2">
                                <h4 className="heading">Help</h4>
                                <ul>
                                    <li><a>Account</a></li>
                                    <li><a>Subscriptions</a></li>
                                    <li><a>FAQs</a></li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </footer>
            </>
        );
    }
}