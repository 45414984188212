import React from 'react';
import Column from 'react-columns';

import './discography.css';
import { Link } from 'react-router-dom';
import Rating from '../../common/rating';

export default function Discography(props) {
    return (
        <>
            <section id="section-discography">
                <div className="container">
                    <Column columns={3} gap="10px" rootStyles={{}}>
                        {
                            props.discography.map((album, i) => {
                                return (
                                    <div key={i} className="post">
                                        <div className="post-header">
                                            <h4 className="text-center">{album.name}</h4>
                                        </div>
                                        <div className="post-media">
                                            <Link to={location => `${location.pathname}/${album.slug}`}>
                                                <img src={album.coverImage} className="img-fluid"/>
                                            </Link>
                                        </div>
                                        <div className="post-body d-flex flex-column text-center">
                                            <Rating stars="4" rating="3.1" ratings="122" />
                                            <Link className="btn btn-outline-primary flex-grow-1" to={location => `${location.pathname}/${album.slug}`}>View album</Link>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </Column>
                </div>

            </section>
        </>
    )
}