import React from 'react';
import './profile.css';

export default class AccountProfile extends React.Component {
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md-12">
                            <h1 className="section-separator">Public profile</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <form>
                                <div className="field">
                                    <label className="label">Name</label>
                                    <div className="form-input">
                                        <input className="form-control" type="text" placeholder="Your full name" name="fullname" id="fullname" />
                                    </div>
                                </div>

                                <div className="field">
                                    <label className="label">E-mail</label>
                                    <div className="form-input">
                                        <input className="form-control" type="text" placeholder="Your e-mail" name="email" id="email" />
                                    </div>
                                </div>

                                <div className="field is-grouped">
                                    <div className="form-input">
                                        <button className="btn btn-info">Update profile</button>
                                    </div>
                                </div>

                            </form>
                        </div>

                        <div className="col-md-3">
                            <img src="/img/account/me.jpg" alt="me" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="section-separator">Probably something else</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="section-separator">Yet another one</h1>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}