import React from 'react';
import { Link } from 'react-router-dom';

export default function About(props) {
    return (
        <>
            <section id="section-about">
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">About</a></li>
                        </ul>
                    </nav>
                    <h1 className="title">About bandrabbit</h1>
                    <h1 className="subtitle">All about bandrabbit</h1>
                </div>
            </section>
        </>
    )
}