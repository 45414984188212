import React, { useEffect, useState } from 'react';
import Columns from 'react-columns';
import apiService from '../../../services/apiservice';
import AlbumWidget from '../../common/albumwidget';

import './genre.css';
import { Link } from 'react-router-dom';

export default function Genre(props) {
    const genreId = props.match.params.genreId;
    const [genre, setGenre] = useState({});
    const [popularAlbums, setPopularAlbums] = useState([]);
    const queries = [{
        columns: 3,
        query: 'min-width: 800px'
    }, {
        columns: 4,
        query: 'min-width: 1000px'
    }];

    useEffect(() => {
        apiService.getGenre(genreId).subscribe(rsp => setGenre(rsp));
    }, [genreId]);

    useEffect(() => {
        apiService.getGenrePopularAlbums(genreId).subscribe(rsp => setPopularAlbums(rsp));
    }, [genreId]);

    if (genre.id === undefined) {
        return null;
    }

    return (
        <>
            <section id="genre">
                <div className="genre-intro">
                    <div className="genre-bg">
                        <div className="container">
                            <h1 className="title">{genre.title}</h1>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <h1 className="title">Popular {genre.title} albums</h1>

                    <div className="row">
                        <div className="col-md-12">
                            <Columns columns={5} gap="10px" queries={queries} rootStyles={{}}>
                                {
                                    popularAlbums.map((album, i) => {
                                        return <AlbumWidget album={album} />
                                    })
                                }
                            </Columns>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}