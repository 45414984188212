const dev = {
    api: {
        basePath: ''
    }, mapBaseKey: 'pk.eyJ1Ijoic20wa2UiLCJhIjoiY2s1eTl6cDMzMDM5YTNkb2ZjNGxiaWFvMiJ9._c6JKT5fFb1ol81bYLqkUQ'
};

const prod = {
    api: {
        basePath: ''
    }, mapBaseKey: 'pk.eyJ1Ijoic20wa2UiLCJhIjoiY2s1eTl6cDMzMDM5YTNkb2ZjNGxiaWFvMiJ9._c6JKT5fFb1ol81bYLqkUQ'
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;

export default config;
