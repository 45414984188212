import React, { useState, useEffect } from 'react';
import apiService from '../../../../services/apiservice';
import { Link } from 'react-router-dom';

import './message.css';

export default function Message(props) {

    const messageId = props.match.params.messageId;
    const [message, setMessage] = useState({});

    useEffect(() => {
        apiService.getMessage(messageId).subscribe(msg => {
            setMessage(msg);
        });
    }, []);

    if (message.id == undefined) {
        return null;
    } else {
        return (
            <>
                <div className="mailmessage-view">
                    <div className="action-buttons">
                        <Link to="/band-studio/messages" className="btn btn-secondary btn-sm mr-1"><i className="fas fa-arrow-left"></i>&nbsp;Back to messages</Link>
                        <button className="btn btn-sm btn-outline-secondary mr-1">
                            <i className="fas fa-reply"></i>&nbsp;Reply
                        </button>
                        <button className="btn btn-sm btn-danger">
                            <i className="far fa-trash-alt"></i>&nbsp;Delete
                        </button>
                    </div>

                    <div className="mailmessage">
                        <div className="d-flex">
                            <div className="mailmessage-title flex-shrink-1">{message.title}</div>
                            <div className="mailmessage-from flex-grow-1">{message.from}</div>
                            <div className="pull-right mailmessage-date flex-shrink-1">
                                {message.date}
                            </div>
                        </div>

                        <div className="mailmessage-content">
                            {message.body}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}