import React from 'react';
import { Route, Link } from 'react-router-dom';
import Dashboard from './dashboard';
import apiService from '../../services/apiservice';
import { connect } from 'react-redux';
import { setBandInfo } from '../../reducers/actions';
import Album from './album';
import Albums from './albums';
import Messages from './messages';
import Posts from './posts';
import Fans from './fans';

import './bandstudio.css';

class BandStudio extends React.Component {
    componentDidMount() {
        apiService.getBandInfo().subscribe(rsp => {
            this.props.setBandInfo(rsp);
        });
    }

    render() {
        return (
            <>
                <section id="band-studio">
                    <div className="band-intro"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2">
                                <aside className="menu">
                                    <p className="menu-label">
                                        General
                                    </p>
                                    <ul className="menu-list">
                                        <li>
                                            <Link className={this.props.location.pathname === this.props.match.url ? 'is-active' : ''} to={this.props.match.path}>Dashboard</Link>
                                        </li>
                                    </ul>
                                    <p className="menu-label">
                                        Albums
                                    </p>
                                    <ul className="menu-list">
                                        {
                                            this.props.band.name ? (
                                                this.props.band.discography.map((album, i) => {
                                                    return (
                                                        <li key={album.id}>
                                                            <Link className={this.props.location.pathname.startsWith(`${this.props.match.url}/album/${album.id}`) ? 'is-active' : ''} to={`${this.props.match.path}/album/${album.id}`}>{album.name}</Link>
                                                        </li>
                                                    )
                                                })
                                            ) : (<></>)
                                        }
                                        <li>
                                            <Link className={this.props.location.pathname === `${this.props.match.url}/albums` ? 'is-active' : ''} to={`${this.props.match.path}/albums`}>View all albums</Link>
                                        </li>
                                    </ul>
                                    <p className="menu-label">
                                        Songs
                                    </p>
                                    <ul className="menu-list">
                                        <li><a>All songs</a></li>
                                    </ul>
                                    <p className="menu-label">
                                        Communication hub
                                    </p>
                                    <ul className="menu-list">
                                        <li>
                                            <Link className={this.props.location.pathname.startsWith(`${this.props.match.url}/messages`) ? 'is-active' : ''} to={`${this.props.match.path}/messages`}>Messages</Link>
                                        </li>
                                        <li>
                                            <Link className={this.props.location.pathname.startsWith(`${this.props.match.url}/posts`) ? 'is-active' : ''} to={`${this.props.match.path}/posts`}>Posts</Link>
                                        </li>
                                        <li>
                                            <Link className={this.props.location.pathname.startsWith(`${this.props.match.url}/fans`) ? 'is-active' : ''} to={`${this.props.match.path}/fans`}>Fans</Link>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                            <div className="col-md-10">
                                <Route exact path={`${this.props.match.url}`} component={Dashboard} />
                                <Route path={`${this.props.match.url}/album/:albumId`} component={Album} />
                                <Route path={`${this.props.match.url}/albums`} component={Albums} />
                                <Route path={`${this.props.match.url}/messages`} component={Messages} />
                                <Route path={`${this.props.match.url}/posts`} component={Posts} />
                                <Route path={`${this.props.match.url}/fans`} component={Fans} />
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapBandInfo = (props) => {
    return {
        band: Object.assign({}, props.bandInfo)
    };
}

export default connect(mapBandInfo, { setBandInfo })(BandStudio);