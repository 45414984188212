import React from 'react';

export default class AlbumDocuments extends React.Component {
    render() {
        return (
            <>
                Documents
            </>
        );
    }
}