import React, { useEffect, useState } from 'react';
import apiService from '../../../../services/apiservice';

import './postlisting.css';
import { Redirect } from 'react-router-dom';

export default function PostListing(props) {

    const [posts, setPosts] = useState([]);
    const [post, setPost] = useState({});

    useEffect(() => {
        apiService.getPosts().subscribe(rsp => {
            setPosts(rsp);
        },[]);
    })

    if (post.id !== undefined) {
        return <Redirect to={`${props.match.url}/${post.id}`} push={true} />
    }

    return (
        <>
            <div className="gen-listings posts">
                <div className="action-buttons">
                    <button className="btn btn-info btn-sm"><i className="fas fa-plus-square"></i>&nbsp;Create community post</button>
                </div>

                {
                    posts.map((post, i) => {
                        return (
                            <div key={post.id} className="d-flex gen-listing" onClick={() => {setPost(post)}}>
                                <div className="gen-listing-selector flex-shrink-1">
                                    <label className="checkbox">
                                        <input type="checkbox" />
                                    </label>
                                </div>

                                <div className="gen-listing-image flex-shrink-1">
                                    <img src={post.image} alt="fd" />
                                </div>

                                <div className="gen-listing-title flex-grow-1">
                                    {post.title}
                                </div>
                                <div className="gen-listing-icons level flex-shrink-1 d-flex">
                                    <div className="level-left likes">
                                        <i className="fas fa-heart"></i>&nbsp;{post.likes}
                                    </div>
                                    <div className="level-left views flex-shrink-1">
                                        <i className="fas fa-tv"></i>&nbsp;{post.views}
                                    </div>
                                </div>
                                <div className="gen-listing-date flex-shrink-1">
                                {post.date}
                                </div>
                            </div>
                        );
                    })
                }
                
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        <li className="page-item disabled">
                        <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a>
                        </li>
                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item">
                        <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}