import React, { useState, useEffect } from 'react';
import Calendar from '../../common/calendar';
import ApiService from '../../../services/apiservice';

import { format } from 'date-fns';

import './posts.css';
import { Link, Redirect } from 'react-router-dom';
import LoadingBar from '../../common/loadingbar/loadingbar';

export default function Posts(props) {

    const [date, setDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [redirectEvent, setRedirectEvent] = useState();

    const onSelectEvent = (event) => {
        setRedirectEvent(event);
    };

    const onDateChanged = (date) => {
        setDate(date);
        setEvents([]);
    };

    const currentMonth = () => {
        setDate(new Date());
        setEvents([]);
    };

    const previousMonth = () => {
        const prevMonth = date.getMonth() - 1;
        let prevYear = date.getFullYear();

        if (prevMonth == 12) {
            prevYear--;
        }

        setEvents([]);
        setDate(new Date(prevYear, prevMonth));
    };

    const nextMonth = () => {
        const nMonth = date.getMonth() + 1;
        let nextYear = date.getFullYear();

        if (nMonth == 1) {
            nextYear++;
        }

        setEvents([]);
        setDate(new Date(nextYear, nMonth));
    };

    const getEvents = (date) => {
        setLoading(true);
        ApiService.getEvents(date).subscribe((events) => {
            setEvents(events);
            setLoading(false);
        });
    };

    useEffect(() => {
        getEvents(date);
    }, [date]);

    if (redirectEvent !== undefined) {
        return <Redirect to={`/u/${redirectEvent.bandSlug}/events/${redirectEvent.eventId}`} push={true} />
    }

    return (
        <>
            <section id="section-posts">
                <div className="container">
                    <div className="row">

                        {/* First column */}
                        <div className="col-md-3">
                            <div className="post">
                                <div className="post-header">
                                    <h4>{props.artist.name}</h4>
                                </div>
                                <div className="post-body">
                                    <div className="post-about">
                                        The story of DAYLIGHT MISERY begins in 2008 by Stamos Kordas and Vassilis Mazaris <Link to={location => `${location.pathname}/about`}>...more</Link>
                                    </div>
                                    <div className="post-about-info">
                                        <div className="post-about-info-title">
                                            Genre
                                        </div>
                                        <div className="post-about-info-value">
                                            Dark Metal
                                        </div>
                                    </div>
                                    <div className="post-about-info">
                                        <div className="post-about-info-title">
                                            Record Label
                                        </div>
                                        <div className="post-about-info-value">
                                            I for an I Records &amp; Media
                                        </div>
                                    </div>
                                    <div className="post-about-info">
                                        <div className="post-about-info-title">
                                            Band Members
                                        </div>
                                        <div className="post-about-info-value">
                                            {
                                                props.artist.info.members.map((member, i) => {
                                                    return (
                                                        <div key={i}>{member.name} - {member.function}</div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="post">
                                <div className="post-header d-flex">
                                    <h4 className="flex-grow-1">Fans</h4>
                                    <div>
                                        <span className="badge badge-success">12K</span>
                                    </div>
                                </div>
                                <div className="post-body">

                                    {
                                        [
                                            {"name":"Dimitris Kordas","img":"/img/account/me.jpg","fanDate":"6 days ago"},
                                            {"name":"Xenofon Zinoviou","img":"/img/bandstudio/fan-1.jpg","fanDate":"8 days ago"},
                                            {"name":"Alicia Konstantourou","img":"/img/bandstudio/fan-2.jpg","fanDate":"12 days ago"},
                                            {"name":"Nora Gikopoulou","img":"/img/bandstudio/fan-10.jpg","fanDate":"12 days ago"},
                                            {"name":"Ioannis Lilimpakis","img":"/img/bandstudio/fan-3.jpg","fanDate":"12 days ago"},
                                        ].map((fan, i) => {
                                            return (
                                                <div key={i} className="fan d-flex">
                                                    <div className="fan-cover-image mr-2">
                                                        <img src={fan.img} alt="" className="img-fluid" />
                                                    </div>
                                                    <div className="fan-info d-flex flex-column justify-content-center">
                                                        <div className="fan-name">{fan.name}</div>
                                                        <div className="fan-date">Became a fan {fan.fanDate}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </div>

                        {/* Main column */}
                        <div className="col-md-6">

                            <div className="post">
                                <div className="post-header d-flex">
                                    <div className="post-from-image mr-2">
                                        <img src="/img/account/me.jpg" alt="" />
                                    </div>
                                    <div className="post-info d-flex flex-column flex-grow-1 justify-content-center">
                                        <div className="post-title">
                                            <strong>{props.artist.name}</strong> uploaded a community post
                                        </div>
                                        <div className="post-date">
                                            2 minutes ago
                                        </div>
                                    </div>
                                </div>
                                <div className="post-body">
                                    Feel free to follow DAYLIGHT MISERY on Spotify and stream our first two albums as the third one is on its way!
                                </div>
                                <div className="post-media">
                                    <img src="/img/gallery/2.jpg" className="img-fluid" />
                                </div>
                                <div className="post-social d-flex">
                                    <div className="reactions d-flex align-items-center flex-shrink-1">
                                        <div className="reaction-item item-thumbsup">
                                            <i className="far fa-thumbs-up"></i>
                                        </div>
                                        <div className="reaction-item item-smile">
                                            <i className="far fa-smile"></i>
                                        </div>
                                        <div className="reaction-item item-heart">
                                            <i className="fas fa-heart"></i>
                                        </div>
                                        <div className="reaction-count">12</div>
                                    </div>
                                    <div className="comments flex-grow-1 text-right">
                                        3 comments
                                    </div>
                                </div>
                                <div className="post-footer d-flex">
                                    <div className="d-flex justify-content-center flex-grow-1 footer-button">
                                        <button className="btn btn-sm btn-link"><i className="far fa-thumbs-up"></i>&nbsp;React</button>
                                    </div>
                                    <div className="d-flex justify-content-center flex-grow-1 footer-button">
                                        <button className="btn btn-sm btn-link"><i className="far fa-comment-dots"></i>&nbsp;Comment</button>
                                    </div>
                                </div>
                            </div>

                            <div className="post">
                                <div className="post-header d-flex">
                                    <div className="post-from-image mr-2">
                                        <img src="/img/account/me.jpg" alt="" />
                                    </div>
                                    <div className="post-info d-flex flex-column flex-grow-1 justify-content-center">
                                        <div className="post-title">
                                            <strong>{props.artist.name}</strong> posted a video
                                        </div>
                                        <div className="post-date">
                                            14 minutes ago
                                        </div>
                                    </div>
                                </div>
                                <div className="post-body">
                                    00:00 Season: Winter (intro) + 01:41 Θλιβερός Χειμώνας (Sorrowful Winter)
                                    "The Great Absence" CD
                                    www.daylightmisery.com
                                </div>
                                <div className="post-media">
                                    <div className="embed-responsive embed-responsive-16by9">
                                        <iframe className="embed-responsive" width="560" height="315" src="https://www.youtube.com/embed/McwDQhIvGfo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                </div>
                                <div className="post-social d-flex">
                                    <div className="reactions d-flex align-items-center flex-shrink-1">
                                        <div className="reaction-item item-thumbsup">
                                            <i className="far fa-thumbs-up"></i>
                                        </div>
                                        <div className="reaction-item item-smile">
                                            <i className="far fa-smile"></i>
                                        </div>
                                        <div className="reaction-item item-heart">
                                            <i className="fas fa-heart"></i>
                                        </div>
                                        <div className="reaction-count">18</div>
                                    </div>
                                    <div className="comments flex-grow-1 text-right">
                                        12 comments
                                    </div>
                                </div>
                                <div className="post-footer d-flex">
                                    <div className="d-flex justify-content-center flex-grow-1 footer-button">
                                        <button className="btn btn-sm btn-link"><i className="far fa-thumbs-up"></i>&nbsp;React</button>
                                    </div>
                                    <div className="d-flex justify-content-center flex-grow-1 footer-button">
                                        <button className="btn btn-sm btn-link"><i className="far fa-comment-dots"></i>&nbsp;Comment</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* Third column */}
                        <div className="col-md-3">
                            <div className="post">
                                <div className="post-header d-flex">
                                    <div className="post-info flex-grow-1">
                                        <div className="post-title">
                                            <strong>
                                                {
                                                    format(date, "LLLL yyyy")
                                                }
                                            </strong>
                                            </div>
                                    </div>
                                    <div className="post-header-right flex-shrink-1">
                                        <a className="calendarIcon" onClick={previousMonth}><i className="fa fa-chevron-left mr-3"></i></a>
                                        <a className="calendarIcon" onClick={currentMonth}><i className="far fa-calendar-check mr-3"></i></a>
                                        <a className="calendarIcon" onClick={nextMonth}><i className="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                                <Calendar events={events} date={date} onDateChanged={onDateChanged} onSelectEvent={onSelectEvent}/>
                                {
                                    loading && <LoadingBar message={"Loading events"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}