import React from 'react';

export default class AlbumPictures extends React.Component {
    render() {
        return (
            <>
                <div className="container-fluid">
                    df
                </div>
            </>
        );
    }
}