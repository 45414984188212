import React from 'react';
import './songs.css';

export default class AlbumSongs extends React.Component {
    render() {
        return (
            <>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Pos</th>
                            <th>Song</th>
                            <th>Duration</th>
                            <th>Size</th>
                            <th><abbr title="Actions"></abbr></th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr>
                            <th>Pos</th>
                            <th>Song</th>
                            <th>Duration</th>
                            <th>Size</th>
                            <th><abbr title="Actions"></abbr></th>
                        </tr>
                    </tfoot>
                    <tbody>
                        {
                            this.props.album.songs.map((song, i) => {
                                return (
                                    <tr key={i}>
                                        <th>{i+1}</th>
                                        <td>{song.name}</td>
                                        <td>{song.duration}</td>
                                        <td>{song.size}</td>
                                        <td></td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </>
        );
    }
}