import React from 'react';
import apiService from '../../../services/apiservice';
import AlbumInfo from './info';
import AlbumSongs from './songs';
import AlbumPictures from './pictures';
import AlbumVideos from './videos';
import AlbumDocuments from './documents';
import { Route, Link } from 'react-router-dom';

import './album.css';

export default class Album extends React.Component {
    state = {
        album: {}
    };

    UNSAFE_componentWillReceiveProps(props) {
        const albumId = props.match.params.albumId;
        if (this.state.album.id != albumId) {
            this.fetchAlbum(albumId);
        }
    }

    fetchAlbum(albumId) {
        this.setState({
            album: {}
        });
        
        apiService.getAlbum(albumId).subscribe(rsp => {
            this.setState({
                album: rsp
            });
        });
    }

    componentDidMount() {
        const albumId = this.props.match.params.albumId;
        if (this.state.album.id != albumId) {
            this.fetchAlbum(albumId);
        }
    }

    render() {
        if (!this.state.album.id) {
            return (<>Loading...</>);
        }

        return (
            <>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <Link to={this.props.match.url} className={this.props.location.pathname === this.props.match.url ? 'nav-link active' : 'nav-link'}>Information</Link>
                </li>

                <li className="nav-item">
                    <Link to={`${this.props.match.url}/songs`} className={this.props.location.pathname === `${this.props.match.url}/songs` ? 'nav-link active' : 'nav-link'}>Songs</Link>
                </li>

                <li className="nav-item">
                    <Link to={`${this.props.match.url}/pictures`} className={this.props.location.pathname === `${this.props.match.url}/pictures` ? 'nav-link active' : 'nav-link'}>Pictures</Link>
                </li>

                <li className="nav-item">
                    <Link to={`${this.props.match.url}/videos`} className={this.props.location.pathname === `${this.props.match.url}/videos` ? 'nav-link active' : 'nav-link'}>Videos</Link>
                </li>

                <li className="nav-item">
                    <Link to={`${this.props.match.url}/documents`} className={this.props.location.pathname === `${this.props.match.url}/documents` ? 'nav-link active' : 'nav-link'}>Documents</Link>
                </li>
            </ul>
            <Route exact path={`${this.props.match.url}`} render={(props) => <AlbumInfo album={this.state.album} />} />
            <Route exact path={`${this.props.match.url}/songs`} render={(props) => <AlbumSongs album={this.state.album} />} />
            <Route exact path={`${this.props.match.url}/pictures`} render={(props) => <AlbumPictures album={this.state.album} />} />
            <Route exact path={`${this.props.match.url}/videos`} render={(props) => <AlbumVideos album={this.state.album} />} />
            <Route exact path={`${this.props.match.url}/documents`} render={(props) => <AlbumDocuments album={this.state.album} />} />
            </>
        )
    }
}