import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Columns from 'react-columns';
import apiService from '../../services/apiservice';

import './discover.css';
import Rating from '../common/rating/rating';
import AlbumWidget from '../common/albumwidget';

export default function Discover(props) {

    const [term, setTerm] = useState('');
    const [results, setResults] = useState({});
    const [band, setBand] = useState({});
    const [searching, setSearching] = useState(false);
    const [posts, setPosts] = useState([]);
    const [albums, setAlbums] = useState([]);

    const rootStyles = {
        overflowX: 'visible'
    };

    useEffect(() => {
        apiService.getDiscoverPosts().subscribe((posts)=> setPosts(posts));
        apiService.getDiscoverAlbums().subscribe((albums)=> setAlbums(albums));
    }, []);

    useEffect(() => {
        if (term.length > 0) {
            setSearching(true);
            apiService.searchMusicTerm(term).subscribe(rsp => {
                setResults(rsp);
                setSearching(false);
            });
        } else {
            setResults({});
        }
    }, [term])

    if (band.name !== undefined) {
        return <Redirect to={`/u/${band.slug}`} push={true} />;
    }

    return (
        <section id="section-discover">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Discover</li>
                    </ol>
                </nav>

                <div className="action-buttons">
                    <div className={`control has-icons-left${searching ? ' is-loading' : ''}`}>
                        <input className="form-control" placeholder="Start typing artist, band or song name" onChange={(e) => setTerm(e.target.value)} />
                    </div>
                </div>

                {
                    results.artists === undefined ? null : (
                        <>
                            <div id="band-results">
                                <h1 className="title">Artists matching your search</h1>
                                <Columns columns={3} gap="10px" rootStyles={rootStyles}>
                                    {
                                        results.artists.map((artist, i) => {
                                            return (

                                                <div key={i} className="card band" onClick={() => setBand(artist)}>
                                                    <img src={artist.image} className="card-img-top" alt="..." />
                                                    <div className="card-body">
                                                        <h5 className="card-title">{artist.name}</h5>
                                                        <p className="card-text">{artist.description}</p>
                                                        <a href="#" className="btn btn-primary">Go somewhere</a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Columns>
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination justify-content-center">
                                        <li className="page-item disabled">
                                        <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                        <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </>
                    )

                }

                {
                    term.length == 0 ? (
                        <>
                            <h1 className="title">Featured artists</h1>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card band">
                                        <img src="/img/home/pl.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Paradise Lost</h5>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                Phasellus nec iaculis mauris.</p>
                                            <a href="#" className="btn btn-primary">Go somewhere</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card band">
                                        <img src="/img/home/dt.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Dark Tranquility</h5>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                Phasellus nec iaculis mauris.</p>
                                            <a href="#" className="btn btn-primary">Go somewhere</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card band">
                                        <img src="/img/home/dm.jpg" className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Daylight Misery</h5>
                                            <p className="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                Phasellus nec iaculis mauris.</p>
                                            <a href="#" className="btn btn-primary">Go somewhere</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">


                                    {
                                        albums.map((album, i) => {
                                            return (
                                                <AlbumWidget album={album} />
                                            )
                                        })
                                    }
                                </div>
                                <div className="col-md-9">

                                    
                                    {
                                        posts.length > 0 ? (
                                            posts.map((post, i) => {
                                                return (
                                                    <div className="post" key={i}>
                                                        <div className="post-header">
                                                            <h4>{post.title}</h4>
                                                        </div>
                                                        <div className="post-media">
                                                            <img src={post.image} className="img-fluid" />
                                                        </div>
                                                        <div className="post-body">
                                                            {post.text}
                                                        </div>
                                                        <div className="post-social d-flex">
                                                            <div className="reactions d-flex align-items-center flex-shrink-1">
                                                                <div className="reaction-item item-thumbsup">
                                                                    <i className="far fa-thumbs-up"></i>
                                                                </div>
                                                                <div className="reaction-item item-smile">
                                                                    <i className="far fa-smile"></i>
                                                                </div>
                                                                <div className="reaction-item item-heart">
                                                                    <i className="fas fa-heart"></i>
                                                                </div>
                                                                <div className="reaction-count">12</div>
                                                            </div>
                                                            <div className="comments flex-grow-1 text-right">
                                                                3 comments
                                                            </div>
                                                        </div>
                                                        <div className="post-footer d-flex">
                                                            <div className="d-flex justify-content-center flex-grow-1 footer-button">
                                                                <button className="btn btn-sm btn-link"><i className="far fa-thumbs-up"></i>&nbsp;React</button>
                                                            </div>
                                                            <div className="d-flex justify-content-center flex-grow-1 footer-button">
                                                                <button className="btn btn-sm btn-link"><i className="far fa-comment-dots"></i>&nbsp;Comment</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (null)
                                    }

                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                        </>
                    ) : (null)
                }
            </div>
        </section>
    )
}