import React, { useState, useEffect} from 'react';
import ReactMapGL from 'react-map-gl';
import { Popup, Marker } from 'react-map-gl';
import ApiService from '../../services/apiservice';
import Calendar from '../common/calendar';
import LoadingBar from '../common/loadingbar/loadingbar';
import { format } from 'date-fns';
import config from '../../config';

import './events.css';
import { Link } from 'react-router-dom';

export default function Events(props) {
    const [viewport, setViewport] = useState({
        latitude: 37.925671,
        longitude: 23.727625,
        zoom: 14,
        width: '100vw',
        height: '100vh'
    });

    console.info(config);


    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState({});
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(true);

    const onDateChanged = (date) => {
        setEvents([]);
        setEvent({});
        setDate(date);
    };

    const onSelectEvent = (event) => {
        setEvent(event);
        setViewport({...viewport, latitude: event.lat, longitude: event.lng});
    };

    const getEvents = (date) => {
        setLoading(true);
        ApiService.getEvents().subscribe(events => {
            setEvents(events);
            setLoading(false);
        });
    };

    useEffect(() => {
        getEvents(date);
    }, [date])
    
    return (
        <>
            <section id="section-about">
                <div className="map">
                    <ReactMapGL
                        mapboxApiAccessToken={config.mapBaseKey}
                        {...viewport}
                        onViewportChange={setViewport}
                        >
                            
                        {
                                events.map((city, i) => {
                                    return (
                                        <Marker key={i} longitude={city.lng} latitude={city.lat}>
                                            <img className="pinmarker" src="/img/pin4.png" onClick={() => setEvent(city)}/>
                                        </Marker>
                                    )
                                })
                        }

                        {
                            event.name !== undefined && <Popup
                            latitude={event.lat}
                            longitude={event.lng}
                            closeButton={true}
                            closeOnClick={false}
                            onClose={() => setEvent({})}
                            anchor="top" >
                                <div className="popup">
                                    <div className="event-name">{event.name}</div>
                                    <div className="event-date">
                                        {
                                            format(new Date(event.datetime), "LLLL dd, yyyy h:mm a")
                                        }
                                    </div>
                                    <div className="event-description">
                                        {event.description}
                                    </div>
                                    <div className="event-actions d-flex align-items-center mt-3">
                                        <div className="flex-grow-1">
                                            <Link to={`/u/${event.bandSlug}/events/${event.eventId}`} className="btn btn-sm btn-outline-primary">
                                                <i className="far fa-calendar-check mr-2"></i>
                                                View event
                                            </Link>
                                        </div>

                                        <div className="flex-shrink-1">
                                            <span className="badge badge-success">{event.rsvp} people are going</span>
                                        </div>
                                    </div>

                                </div>
                        </Popup>
                        }
                        
                    </ReactMapGL>
                </div>
                <div className="map-controls">
                    <Calendar 
                        events={events} 
                        date={date} 
                        onDateChanged={onDateChanged}
                        onSelectEvent={onSelectEvent}
                    />
                    {
                        loading && <LoadingBar message={"Loading events"} />
                    }
                </div>
            </section>
        </>
    )
}