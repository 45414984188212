import React from 'react';

export default function About(props) {
    return (
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div className="post">
                            <div className="post-header">
                                <h4>{props.artist.name}</h4>
                            </div>
                            <div className="post-media">
                                <img src={props.artist.coverImage} className="img-fluid" />
                            </div>
                            <div className="post-body">
                                {props.artist.description}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        {
                            props.artist.info.members.map((member, i) => {
                                return (
                                    <div key={i} className="post">
                                        <div className="post-header">
                                            <h4>{member.name}</h4>
                                        </div>
                                        <div className="post-media">
                                            <img src={member.img} className="img-fluid" />
                                        </div>
                                        <div className="post-body">
                                            {member.description}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>

                </div>
            </div>
        )
}