import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import './info.css';

require("highcharts/modules/annotations")(Highcharts);

export default class AlbumInfo extends React.Component {
    render() {
        let options = {
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },
            yAxis: {
                title: {
                    text: "Revenue ($)"
                }
            },
            title: {
                text: 'Sales over time'
            },

            series: [{
                data: this.props.album.data
            }]
        }

        return (
            <>
                <div className="row">
                    <div className="col-md-3">
                        <img src={this.props.album.coverImage} alt="cover image" className="img-fluid" />
                        <h4 className="is-size-4">{this.props.album.name}</h4>
                        <p>Released {this.props.album.releaseDate}</p>
                    </div>
                    <div className="col-md-9">
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'chart'}
                            options={options} />
                    </div>
                </div>
            </>
        );
    }
}