import { SET_MUSIC_TRACK } from './actions';

const initialState = {
};

export default function userInfo(state = initialState, action) {
  switch (action.type) {
    case SET_MUSIC_TRACK: {
      return Object.assign({}, action.track);
    }
    default:
      return state;
  }
}