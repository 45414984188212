import React, { useEffect, useState } from 'react';

import './artist.css';
import { Route, Link } from 'react-router-dom';
import Events from './events';
import Posts from './posts';
import Discography from './discography';
import Gallery from './gallery/gallery';
import apiService from '../../services/apiservice';
import Album from './discography/album';
import About from './about/about';

export default function Artist(props) {

    const artistId = props.match.params.artistId;
    const [artist, setArtist] = useState({});

    useEffect(() => {
        apiService.getArtist(artistId).subscribe(rsp => {
            setArtist(rsp);
        });
    }, []);

    if (artist.name === undefined) {
        return null;
    }

    return (
        <>
            <section id="section-artist">
                <div className="artist-intro" style={{backgroundImage: `url(${artist.coverImage})`}}></div>
                <div className="artist-nav">
                    <div className="container">
                        <div className="d-flex">
                            <div className="mr-auto d-flex">

                                <div className="stat">
                                    <div className="metric-value text-center">{artist?.stats?.fans}</div>
                                    <div className="metric-title text-center">Fans</div>
                                </div>

                                <div className="stat">
                                    <div className="metric-value text-center">{artist?.stats?.posts}</div>
                                    <div className="metric-title text-center">Posts</div>
                                </div>

                                <div className="stat">
                                    <div className="metric-value text-center">{artist?.stats?.likes}</div>
                                    <div className="metric-title text-center">Likes</div>
                                </div>

                                <div className="stat">
                                    <div className="metric-value flag text-center">
                                        <img src={`/img/flags/${artist?.stats?.countryCode}.png`} />
                                    </div>
                                    <div className="metric-title text-center">{artist?.stats?.countryName}</div>
                                </div>
                            </div>
                            <div className="form-inline">
                                <a className="btn btn-social btn-facebook btn-sm mr-1">
                                    <svg id="svg-facebook" viewBox="0 0 18 18" preserveAspectRatio="xMinYMin meet">
                                        <path d="M16.313,18h-4.504v-6.192h3.087V8.671h-3.087V7.135V6.831V6.814l0,0c0.01-0.328,0.277-0.591,0.607-0.591h0.067h1.113h1.62V3.086h-2.733l0,0l-0.009,0.018h-0.092c-2.051,0-3.712,1.661-3.712,3.711v0.911v0.945H6.191v3.137h2.479V18H1.687C0.755,18,0,17.242,0,16.313V1.686C0,0.754,0.755,0,1.687,0h14.626C17.244,0,18,0.754,18,1.686v14.627C18,17.242,17.244,18,16.313,18z"></path>
                                    </svg>
                                </a>
                                <a className="btn btn-social btn-twitter btn-sm mr-1">
                                    <svg id="svg-twitter" viewBox="0 0 18 18" preserveAspectRatio="xMinYMin meet">
                                        <path d="M18,3.617c-0.675,0.322-1.351,0.43-2.139,0.537c0.788-0.43,1.351-1.076,1.576-1.937c-0.675,0.43-1.463,0.646-2.362,0.861C14.399,2.431,13.388,2,12.375,2c-1.912,0-3.601,1.617-3.601,3.554c0,0.323,0,0.538,0.113,0.754C5.851,6.2,3.038,4.8,1.238,2.646C0.9,3.185,0.788,3.724,0.788,4.478c0,1.184,0.675,2.261,1.688,2.907c-0.563,0-1.125-0.216-1.688-0.431l0,0c0,1.723,1.237,3.122,2.925,3.446c-0.338,0.107-0.675,0.107-1.013,0.107c-0.225,0-0.45,0-0.675-0.107c0.45,1.399,1.8,2.476,3.487,2.476C4.274,13.846,2.7,14.384,0.9,14.384c-0.338,0-0.563,0-0.9,0C1.688,15.354,3.601,16,5.625,16c6.75,0,10.462-5.385,10.462-10.015c0-0.108,0-0.323,0-0.431C16.875,5.016,17.549,4.371,18,3.617z"></path>
                                    </svg>
                                </a>
                                <a className="btn btn-social btn-instagram btn-sm mr-1">
                                    <svg id="svg-instagram" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
                                        <path d="M72.402,0H27.595C12.379,0,0,12.381,0,27.597v44.809C0,87.619,12.379,100,27.595,100h44.807C87.621,100,100,87.619,100,72.406V27.597C100,12.381,87.621,0,72.402,0z M88.691,71.078c0,9.711-7.902,17.613-17.615,17.613H28.923c-9.712,0-17.613-7.902-17.613-17.613V28.926c0-9.715,7.901-17.618,17.613-17.618h42.153c9.713,0,17.615,7.903,17.615,17.618V71.078z M50.001,26.779c-12.804,0-23.22,10.426-23.22,23.22c0,12.804,10.416,23.22,23.22,23.22c12.801,0,23.217-10.416,23.217-23.22C73.219,37.205,62.803,26.779,50.001,26.779z M50.001,63.689c-7.548,0-13.688-6.139-13.688-13.691c0-7.545,6.14-13.684,13.688-13.684c7.545,0,13.686,6.139,13.686,13.684C63.688,57.551,57.547,63.689,50.001,63.689z M75.658,17.864c-1.711,0-4.383,0.724-5.711,2.056c-1.332,1.328-2.098,3.178-2.098,5.065c0,1.882,0.766,3.727,2.098,5.063c1.326,1.325,3.172,2.098,5.051,2.098c1.885,0,3.723-0.772,5.055-2.098c1.332-1.336,2.094-3.184,2.094-5.063c0-1.888-0.762-3.737-2.094-5.065C78.729,18.587,77.373,17.864,75.658,17.864z"></path>
                                    </svg>
                                </a>
                                <a className="btn btn-social btn-twitch btn-sm mr-1">
                                    <svg id="svg-twitch" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
                                        <path d="M6.179,0L0,17.964V87.81h24.714V100h15.789L52.86,87.81h18.993L100,60.527V0H6.179z M90.39,57.645L74.828,72.504H49.885L37.299,84.703V72.504H15.561V9.094H90.39V57.645z M49.885,27.051h-9.382v27.27h9.382V27.051z M74.828,27.051h-9.382v27.27h9.382V27.051z"></path>
                                    </svg>
                                </a>
                                <a className="btn btn-social btn-youtube btn-sm mr-1">
                                    <svg id="svg-youtube" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
                                    <path d="M97.911,24.557c-1.149-4.549-4.538-8.13-8.842-9.347C81.271,13,49.999,13,49.999,13s-31.271,0-39.07,2.21c-4.302,1.216-7.69,4.798-8.84,9.347C0,32.8,0,50.001,0,50.001s0,17.2,2.089,25.443c1.15,4.549,4.538,8.132,8.84,9.348C18.729,87,49.999,87,49.999,87s31.271,0,39.07-2.208c4.304-1.216,7.692-4.799,8.842-9.348C100,67.201,100,50.001,100,50.001S100,32.8,97.911,24.557z M39.999,65.858V34.144L65.98,50.001L39.999,65.858z"></path>
                                    </svg>
                                </a>
                                <a className="btn btn-social btn-patreon btn-sm mr-1">
                                    <svg id="svg-patreon" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
                                        <path d="M0,100h16.667V0H0V100z M62.5,0C41.79,0,25,16.788,25,37.5c0,20.71,16.789,37.503,37.5,37.503c20.711,0,37.5-16.793,37.5-37.503C100,16.788,83.211,0,62.5,0z"></path>
                                    </svg>
                                </a>
                                <a className="btn btn-social btn-discord btn-sm mr-1">
                                    <svg id="svg-discord" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
                                        <path d="M87.671,23.75C75.993,15.223,64.855,15,63.707,15c-0.061,0-0.093,0-0.093,0l-1.2,1.332c14.556,4.332,21.32,10.582,21.32,10.582c-8.906-4.749-17.639-7.083-25.771-8c-2.7-0.291-5.353-0.422-7.926-0.422c-3.287,0-6.437,0.216-9.371,0.591c-0.512,0-0.941,0.084-1.456,0.165c-2.994,0.252-10.273,1.334-19.434,5.249c-3.167,1.417-5.052,2.417-5.052,2.417s7.107-6.583,22.518-10.915L36.387,15c0,0-0.032,0-0.098,0c-1.144,0-12.283,0.223-23.96,8.75C12.329,23.75,0,45.498,0,72.333c0,0,7.19,12.081,26.114,12.667c0,0,3.166-3.752,5.734-6.919c-10.873-3.168-14.981-9.831-14.981-9.831s0.854,0.582,2.397,1.414l0.343,0.25l0.769,0.419c2.14,1.163,4.282,2.084,6.251,2.833c3.511,1.334,7.706,2.663,12.584,3.585c3.51,0.633,7.349,1.047,11.451,1.047c3.408,0,6.999-0.284,10.728-0.964c4.021-0.67,8.129-1.836,12.415-3.585c2.991-1.083,6.333-2.665,9.845-4.916c0,0-4.287,6.831-15.5,9.915C70.717,81.414,73.805,85,73.805,85C92.722,84.414,100,72.333,100,72.333C100,45.498,87.671,23.75,87.671,23.75z M34.869,66.745c-5.046,0-9.136-4.056-9.136-9.059c0-5.002,4.09-9.059,9.136-9.059c5.045,0,9.137,4.057,9.137,9.059C44.006,62.689,39.914,66.745,34.869,66.745z M67.758,66.745c-5.045,0-9.135-4.056-9.135-9.059c0-5.002,4.09-9.059,9.135-9.059c5.046,0,9.137,4.057,9.137,9.059C76.895,62.689,72.804,66.745,67.758,66.745z"></path>
                                    </svg>
                                </a>

                                <button className="btn btn-outline-bandrabbit btn-sm">
                                    <i className="far fa-bell"></i>
                                    &nbsp;Become a fan
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="artist-menu d-flex">

                                <div className="menu-item">
                                    <Link to={props.match.url} className={`text-center ${props.location.pathname === props.match.url ? 'active' : ''}`}>
                                        Community
                                    </Link>
                                </div>
                                <div className="menu-item">
                                    <Link to={`${props.match.url}/discography`} className={`text-center ${props.location.pathname.startsWith(`${props.match.url}/discography`) ? 'active' : ''}`}>
                                        Discography
                                    </Link>
                                </div>
                                <div className="menu-item">
                                    <Link to={`${props.match.url}/gallery`} className={`text-center ${props.location.pathname.startsWith(`${props.match.url}/gallery`) ? 'active' : ''}`}>
                                        Gallery
                                    </Link>
                                </div>
                                <div className="menu-item">
                                    <Link to={`${props.match.url}/events`} className={`text-center ${props.location.pathname.startsWith(`${props.match.url}/events`) ? 'active' : ''}`}>
                                        Events
                                    </Link>
                                </div>
                                <div className="menu-item">
                                    <Link to={`${props.match.url}/about`} className={`text-center ${props.location.pathname.startsWith(`${props.match.url}/about`) ? 'active' : ''}`}>
                                        About
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    artist.name !== undefined ? (
                        <>
                        <Route path={props.match.path} exact render={() => <Posts artist={artist}/>} />
                        <Route path={`${props.match.path}/events`} component={Events} />
                        <Route path={`${props.match.path}/gallery`} render={() => <Gallery gallery={artist.gallery} />} />
                        <Route exact path={`${props.match.path}/discography`} render={() => <Discography discography={artist.discography} match={props.match} />} />
                        <Route exact path={`${props.match.path}/discography/:albumSlug`} render={(props) => <Album {...props} discography={artist.discography} />} />
                        <Route path={`${props.match.path}/about`} render={() => <About artist={artist}/>} />
                        </>
                    ) : (null)
                }


            </section>
        </>
    )
}