import { BAND_INFO_UPDATE } from './actions';

const initialState = {
    "name": "",
    "formation": ""
};

export default function bandInfo(state = initialState, action) {
  switch (action.type) {
    case BAND_INFO_UPDATE: {
      return Object.assign({}, action.bandInfo);
    }
    default:
      return state;
  }
}