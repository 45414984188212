import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './musicplayer.css';
import { setMusicTrack } from '../../../reducers/actions';
import { Link } from 'react-router-dom';

export default function MusicPlayer() {
    const [audioContext, setAudioContext] = useState({});
    const [buffer, setBuffer] = useState();
    const [audioTrack, setAudioTrack] = useState();
    const [audioSource, setAudioSource] = useState();
    const [currentTime, setCurrentTime] = useState();

    const track = useSelector(state => state.track);
    const dispatch = useDispatch();

    const closePlayer = () => {
        dispatch(setMusicTrack({}));
    }

    useEffect(() => {
        setAudioTrack(track);
        setAudioContext(new (window.AudioContext || window.webkitAudioContext)());
    }, [track]);

    useEffect(() => {
        if (audioContext === undefined || audioTrack === undefined) {
            return;
        }

        let request = new XMLHttpRequest();
        request.open('GET', track.url, true);
        request.responseType = 'arraybuffer';
    
        request.onload = function() {
            audioContext.decodeAudioData(request.response, function(buffer) {
                setBuffer(buffer);
            })
        };
    
        request.send();
    }, [audioContext, track]);

    useEffect(() => {
        if (buffer === undefined) {
            return;
        }

        console.info('ok');
        var source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);

        setAudioSource(source);
    }, [buffer]);

    useEffect(() => {
        if (audioSource === undefined) {
            return;
        }

        audioSource.start(0);
    }, [audioSource]);

    useEffect(() => {
        if (audioContext === undefined) {
            return;
        }

        setCurrentTime(audioContext.currentTime);
    }, [audioContext.currentTime])

    if (track.name === undefined || audioContext === undefined || audioSource === undefined) {
        return null;
    }

    return (
        <>
            <div id="music-player" className="d-flex align-items-center">
                <Link to={`/u/${track.artist.slug}/discography/${track.album.slug}`}>
                    <div className="track-info d-flex flex-shrink-1 mr-4">
                        <div className="info-image mr-2 flex-shrink-1 d-flex align-items-center">
                            <img className="img-fluid" src={track.album.coverImage} />
                        </div>
                        <div className="info-desc d-flex flex-grow-1 d-flex flex-column mr-2">
                            <div className="track-title">{track.name}</div>
                            <div className="track-band">{track.artist.name}</div>
                        </div>
                        <div className="info-link d-flex flex-shrink-1 align-items-center">
                            <i className="fas fa-external-link-alt"></i>
                        </div>
                    </div>
                </Link>

                <div className="controls d-flex flex-shrink-1 mr-2 align-items-center">
                    <a className="flex-grow-1">
                        <i className="fas fa-step-backward"></i>
                    </a>
                    <a className="flex-grow-1">
                        <i className="fas fa-play-circle"></i>
                    </a>
                    <a className="flex-grow-1">
                        <i className="fas fa-step-forward"></i>
                    </a>
                </div>

                <div className="track-progress d-flex flex-grow-1 align-items-center mr-5">
                    <div className="progress flex-grow-1 mr-2" style={{height: "20px"}}>
                        <div className="progress-bar" role="progressbar" style={{width: "25%"}}></div>
                    </div>

                    <div className="progress-duration flex-shrink-1">
                        {track.duration}
                    </div>
                </div>

                <div className="options d-flex flex-shrink-1 align-items-center mr-2">
                    <a className="mr-2 flex-grow-1">
                        <i className="fas fa-heart"></i>
                    </a>
                    <a className="mr-2 flex-grow-1">
                        <i className="fas fa-random"></i>
                    </a>
                    <a className="mr-2 flex-grow-1">
                        <i className="fas fa-retweet"></i>
                    </a>
                    <a className="mr-2 flex-grow-1">
                        <i className="fas fa-volume-down"></i>
                    </a>
                </div>

                <div className="close flex-shrink-1 align-items-center">
                    <a onClick={closePlayer}>
                        <i className="far fa-times-circle"></i>
                    </a>
                </div>

            </div>
        </>
    )
};