import React from 'react';
import './fourohfour.css';
import { Link } from 'react-router-dom';

export default class FourOhFour extends React.Component {
    render() {
        return (
            <>
                <div className="container" id="c-404">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">Page not found</a></li>
                        </ul>
                    </nav>

                    <h1 className="title">Uh-oh!</h1>
                    <h2 className="subtitle">The page you requested cannot be found.</h2>

                    <Link to="/" className="button">Click here to go back to the homepage</Link>
                </div>
            </>
        );
    }
}