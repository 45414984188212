import React from 'react';
import { Link } from 'react-router-dom';

import './albumwidget.css';

export default function AlbumWidget(props) {
    const album = props.album;
    return <div className="post">
        <div className="post-media">
            <Link to={`/u/${album.artist.slug}/discography/${album.slug}`}>
                <img src={album.image} className="img-fluid" />
            </Link>
        </div>
        <div className="post-body">
            <h5>{album.title}</h5>
            <h6>{album.artist.title}</h6>
        </div>
    </div>
}