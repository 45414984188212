import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import AccountDashboard from './dashboard';
import AccountProfile from './profile';
import AccountSecurity from './security';
import AccountNotifications from './notifications';

class Account extends React.Component {
    render() {
        return (
            <>
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">Account overview</a></li>
                        </ul>
                    </nav>

                    <h1 className="title">My account</h1>
                    <h2 className="subtitle">Your account settings, all in one place.</h2>

                    <div className="row">
                        <div className="col-md-2">
                            <aside className="menu">
                                <p className="menu-label">
                                    General
                                    </p>
                                <ul className="menu-list">
                                    <li>
                                        <Link className={this.props.location.pathname === this.props.match.url ? 'is-active' : ''} to={this.props.match.url}>Dashboard</Link>
                                    </li>
                                </ul>
                                <p className="menu-label">
                                    Administration
                                    </p>
                                <ul className="menu-list">
                                    <li><Link className={this.props.location.pathname === `${this.props.match.url}/profile` ? 'is-active' : ''} to={`${this.props.match.url}/profile`}>Personal profile</Link></li>
                                    <li><Link className={this.props.location.pathname === `${this.props.match.url}/security` ? 'is-active' : ''} to={`${this.props.match.url}/security`}>Security</Link></li>
                                    <li><Link className={this.props.location.pathname === `${this.props.match.url}/notifications` ? 'is-active' : ''} to={`${this.props.match.url}/notifications`}>Notifications</Link></li>
                                </ul>
                                <p className="menu-label">
                                    Subscription
                                    </p>
                                <ul className="menu-list">
                                    <li>
                                        <Link className={this.props.location.pathname === `${this.props.match.url}/my-band` ? 'is-active' : ''} to={`${this.props.match.url}/my-band`}>My Band</Link>
                                    </li>
                                    <li>
                                        <a>Payment methods</a>
                                    </li>
                                </ul>
                            </aside>
                        </div>
                        <div className="column">
                            <Switch>
                                <Route exact path={`${this.props.match.path}`} component={AccountDashboard} />
                                <Route exact path={`${this.props.match.path}/profile`} component={AccountProfile} />
                                <Route exact path={`${this.props.match.path}/security`} component={AccountSecurity} />
                                <Route exact path={`${this.props.match.path}/notifications`} component={AccountNotifications} />
                            </Switch>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapUserInfo = (state) => {
    return {
        userInfo: Object.assign({}, state.userInfo)
    };
};

export default connect(mapUserInfo)(Account);