import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setMusicTrack } from '../../../../reducers/actions';

import './album.css';

function Album(props) {

    const [album, setAlbum] = useState({});
    const {albumSlug, artistId} = props.match.params;

    useEffect(() => {
        setAlbum(props.discography.find(a => a.slug === albumSlug));
    }, [album]);


    if (album.name === undefined) {
        return null;
    }

    return (
        <>
            <section id="section-album">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="post">
                                <div className="post-media">
                                    <img src={album.coverImage} className="img-fluid" />
                                </div>
                                <div className="post-body">
                                    <div className="post-about-info">
                                        <div className="post-about-info-title">
                                            Record Label
                                        </div>
                                        <div className="post-about-info-value">
                                            {album.recordLabel}
                                        </div>
                                    </div>
                                    <div className="post-about-info">
                                        <div className="post-about-info-title">
                                            Album type
                                        </div>
                                        <div className="post-about-info-value">
                                            {album.type}
                                        </div>
                                    </div>
                                    <div className="post-about-info">
                                        <div className="post-about-info-title">
                                            Release date
                                        </div>
                                        <div className="post-about-info-value">
                                            {album.releaseDate}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="post">
                                <div className="post-header d-flex">
                                    <h4 className="flex-grow-1">{album.name}</h4>
                                    <Link to="../discography" className="btn btn-sm btn-outline-secondary flex-shrink-1">
                                        <i className="fa fa-chevron-left" />&nbsp;Back to discography
                                    </Link>
                                </div>
                                <div className="post-body">
                                    {album.description}
                                </div>
                            </div>
                            <div className="post">
                                <div className="post-header d-flex">
                                    <h4 className="flex-grow-1">Tracks</h4>
                                    <div>
                                        <span className="badge badge-success">{album.tracks.length}</span>
                                    </div>
                                </div>
                                <div className="post-body">
                                    {
                                        album.name !== undefined ? (
                                            album.tracks.map((track, i) => {
                                                return (
                                                    <div className="track d-flex" key={i}>
                                                        <div className="track-index mr-2">{i+1}</div>
                                                        <div className="track-title flex-grow-1">{track.name}</div>
                                                        <div className="track-duration">{track.duration}</div>
                                                    </div>
                                                )
                                            })
                                        ) : (null)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default connect(null, { setMusicTrack })(Album);