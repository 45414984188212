import React, { useEffect, useState } from 'react';
import ApiService from '../../services/apiservice';
import LoadingBar from '../common/loadingbar';
import { useLocation, Link } from 'react-router-dom';

import './music.css';
import { Route } from 'react-router-dom';
import Playlist from './playlist/playlist';
import About from '../about';

export default function Music() {

    const [playlists, setPlaylists] = useState([]);
    let [loading, setLoading] = useState(false);
    let location = useLocation();

    useEffect(() => {
        setLoading(true);
        ApiService.getPlaylists().subscribe((playlists) => {
            setPlaylists(playlists);
            setLoading(false);
        });
    }, []);

    return (
        <div className="container" id="section-music">
            <div className="row">
                <div className="col-md-3">

                    <div className="post">
                        <div className="post-header">
                            <h4>Music controls</h4>
                        </div>

                        <div className="post-nav">
                            <ul>
                                <li>
                                    <a><i className="far fa-plus-square mr-2"></i>Create playlist</a>
                                </li>

                                <li className="header">Playlists</li>

                                {
                                    loading && <LoadingBar message={"Loading playlists"} />
                                }

                                {
                                    playlists.map((playlist) => {
                                        return <li key={playlist.id}>
                                            <Link to={`/music/p/${playlist.id}`}>{playlist.name}</Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-9">
                    <Route path="/music/p/:playlistId" exact component={Playlist} />
                    <Route path="/music" exact render={() => 'Music'} />
                </div>
            </div>
        </div>        
    )
}