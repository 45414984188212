import React from 'react';
import './apploading.css';
import LoadingBar from '../loadingbar/loadingbar';

export default class AppLoading extends React.Component {
    render() {
        return (
            <>
                <div id="body-container">
                    <div id="loading" className="text-center">
                        <img src="/img/common/logo_inverted.png" alt="logo" /> 
                        <LoadingBar message={"Loading your musical experience"} />
                    </div>
                </div>
            </>
        )
    }
}