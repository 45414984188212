import React from 'react';

export default class AccountDashboard extends React.Component {
    render() {
        return (
            <>
                dashboard
            </>
        );
    }
}