import React from 'react';

import './loadingbar.css';

export default function LoadingBar(props) {
    let message = props.message;
    if (message === undefined) {
        message = "Loading";
    }

    return <>
        <div className="loader-container d-flex flex-column">
            <div className="loader-message text-center">
                <h5>{message}</h5>
            </div>
            <div className="loading-bars d-flex justify-content-center">
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
            </div>
        </div>
    </>
}