import { combineReducers } from 'redux';
import userInfo from './userinfo';
import bandInfo from './band';
import track from './track';

const rootReducer = combineReducers({
  userInfo,
  bandInfo,
  track
});

export default rootReducer;