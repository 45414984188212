import Axios from 'axios-observable';
import { retry, map, delay } from 'rxjs/operators';
import config from '../config';

const delayWrapper = (min=0, max=0) => {
    return delay(Math.random() * (max - min) + min);
};

class ApiService {
    login() {
        return Axios.get(`${config.api.basePath}/mockrest/login_success.json`)
        .pipe(
            map(rsp => {
                return rsp.data;
            }),
            retry(3),
            delayWrapper()
        );
    }

    getPlaylist(id) {
        return Axios.get(`${config.api.basePath}/mockrest/playlist_${id}.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            }),
            delayWrapper()
        );
    }

    getPlaylists() {
        return Axios.get(`${config.api.basePath}/mockrest/playlists.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.playlists;
            }),
            delayWrapper()
        );
    }

    getEvents(date) {
        return Axios.get(`${config.api.basePath}/mockrest/events.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.events;
            }),
            delayWrapper()
        );
    }

    searchMusicTerm(term) {
        return Axios.get(`${config.api.basePath}/mockrest/music_search.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            }), 
            delayWrapper()
        );
    }

    getFans() {
        return Axios.get(`${config.api.basePath}/mockrest/fans.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.fans;
            })
        );
    }

    getDiscoverAlbums() {
        return Axios.get(`${config.api.basePath}/mockrest/discover/albums.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.albums;
            })
        );
    }

    getDiscoverPosts() {
        return Axios.get(`${config.api.basePath}/mockrest/discover/posts.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.posts;
            })
        );
    }

    getPosts() {
        return Axios.get(`${config.api.basePath}/mockrest/posts.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.posts;
            })
        );
    }

    getMessages() {
        return Axios.get(`${config.api.basePath}/mockrest/messages.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload.messages;
            })
        );
    }

    getPost(id) {
        return Axios.get(`${config.api.basePath}/mockrest/post_${id}.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    getMessage(id) {
        return Axios.get(`${config.api.basePath}/mockrest/message_${id}.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    getGenre(genreId) {
        return Axios.get(`${config.api.basePath}/mockrest/genre_${genreId}.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    getArtist(artistId) {
        return Axios.get(`${config.api.basePath}/mockrest/artist_info_${artistId.toLowerCase()}.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    getBandInfo() {
        return Axios.get(`${config.api.basePath}/mockrest/band_info.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    getGenrePopularAlbums(genreId) {
        return Axios.get(`${config.api.basePath}/mockrest/genre_${genreId}_popular.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    getAlbum(albumId) {
        return Axios.get(`${config.api.basePath}/mockrest/album_${albumId}.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            })
        );
    }

    isLoggedIn() {
        return Axios.get(`${config.api.basePath}/mockrest/isloggedin_success.json`)
        .pipe(
            map(rsp => {
                return rsp.data;
            }),
            delayWrapper()
        );
    }

    getPlaces() {
        return Axios.get(`${config.api.basePath}/mockrest/account/places/getPlaces.json`)
        .pipe(
            map(rsp => {
                return rsp.data.payload;
            }),
            delayWrapper()
        );
    }
}

export default new ApiService();