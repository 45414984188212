import React from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
require("highcharts/modules/annotations")(Highcharts);

export default class Dashboard extends React.Component {
    render() {
        let options = {
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            },
            yAxis: {
                title: {
                    text: "Revenue ($)"
                }
            },
            title: {
                text: 'Sales over time'
            },
        
            series: [{
                data: [
                    { y: 29.9, id: 'min' }, 
                    71.5, 
                    106.4, 
                    129.2, 
                    144.0, 
                    176.0, 
                    135.6, 
                    148.5, 
                    { y: 216.4, id: 'max' }, 
                    194.1, 
                    95.6, 
                    54.4
                ]
            }],
        
            annotations: [{
                labels: [{
                    point: 'max',
                    text: 'Depressive Icons'
                }, {
                    point: 'min',
                    text: 'The Great Absence'
                }]
            }]
        }
        return (
            <>
                <section id="band-dashboard">
                    <div className="container">
                        <div className="post">
                        <HighchartsReact
                            highcharts={Highcharts}
                            constructorType={'chart'}
                            options={options} />
                        </div>
                    </div>
                </section>
            </>
        );
    }
}