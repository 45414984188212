import React from 'react';
import apiService from '../../services/apiservice';
import { Link } from 'react-router-dom';

export default class Register extends React.Component {
    state = {
        step: 1,
        form: {
            name: {
                value: '',
                valid: false,
                touched: false
            },
            email: {
                value: '',
                valid: false,
                touched: false
            },
            password: {
                value: '',
                valid: false,
                touched: false
            },
            passwordRepeat: {
                value: '',
                valid: false,
                touched: false
            },
            conditions: {
                value: false,
                valid: false
            }
        },
        isWorking: false,
    };

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.calculateFormState = this.calculateFormState.bind(this);
        this.step1 = this.step1.bind(this);
        this.step2 = this.step2.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            isWorking: true
        });

        apiService.login().subscribe(
            rsp => {
                this.setState({
                    isWorking: false,
                    step: 2
                });
            }, error => {
                this.setState({
                    isWorking: false
                });
            }
        );
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const form = Object.assign({}, this.state.form);
        form[name].value = value;
        form[name].touched = true;
        this.calculateFormState(form);

        this.setState((state, props) => {
            return {
                form: form
            };
        });
    }

    calculateFormState(form) {
        const keys = Object.keys(form);

        // Empty values
        for (let i=0;i<keys.length;i++) {
            const key = keys[i];
            const val = form[key].value;
            form[key].valid = val.length !== 0;
        }

        // Email validity. Simplest of regexes. Rest happens with e-mail verification.
        const emailValue = form['email'].value;
        form['email'].valid = emailValue.match(/^\S+@\S+$/) !== null;

        // Password validity.
        const passwordValue = form['password'].value;
        const passwordRepeatValue = form['passwordRepeat'].value;

        form['password'].valid = (
            (passwordValue === passwordRepeatValue) &&
            (passwordValue.length > 8)
        );

        form['passwordRepeat'].valid = (
            (passwordValue === passwordRepeatValue) &&
            (passwordRepeatValue.length > 8)
        );
        
        form['conditions'].valid = form['conditions'].value;
    }

    step1() {
        const emailInvalid = !this.state.form.email.valid && this.state.form.email.touched;
        const nameInvalid = !this.state.form.name.valid && this.state.form.name.touched;
        const passwordInvalid = !this.state.form.password.valid && this.state.form.password.touched;
        const passwordRepeatInvalid = !this.state.form.passwordRepeat.valid && this.state.form.passwordRepeat.touched;
        const formValid = this.state.form.name.valid && this.state.form.email.valid && this.state.form.password.valid && this.state.form.passwordRepeat.valid && this.state.form.conditions.valid;
        const isWorking = this.state.isWorking;

        return (
            <>
            <section className="section">
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">Sign up</a></li>
                        </ul>
                    </nav>

                    <form noValidate onSubmit={this.handleSubmit}>
                        <h1 className="title">Please fill in this form to create your Bandrabbit account.</h1>
                        <h2 className="subtitle">All fields are required.</h2>


                        <div className="box">
                            <div className="field">
                                <label className="label">Name</label>
                                <div className="form-input">
                                    <input className={`input${nameInvalid ? ' is-danger' : ''}`} type="text" placeholder="Your full name" name="name" id="name" value={this.state.form.name.value} onChange={this.handleInputChange} />
                                </div>
                                {
                                    nameInvalid ? (<p className="help is-danger">Please fill in your name</p>):(<></>)
                                }
                            </div>

                            <div className="field">
                                <label className="label">Your email</label>
                                <div className="form-input has-icons-left has-icons-right">
                                    <input className={`input${emailInvalid ? ' is-danger' : ''}`} type="email" placeholder="Your email address" value={this.state.form.email.value} onChange={this.handleInputChange} name="email"/>
                                    <span className="icon is-small is-left">
                                    <i className="fas fa-envelope"></i>
                                    </span>
                                </div>
                                {
                                    emailInvalid ? (<p className="help is-danger">This email is invalid</p>):(<></>)
                                }
                            </div>

                            <div className="field">
                                <label className="label">Choose your password</label>
                                <div className="form-input has-icons-left has-icons-right">
                                    <input className={`input${passwordInvalid ? ' is-danger' : ''}`} type="password" placeholder="Enter your desired password" value={this.state.form.password.value} onChange={this.handleInputChange} name="password"/>
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </div>
                                {
                                    passwordInvalid ? (<p className="help is-danger">Your password is invalid. Make sure your password match and are longer than 8 characters.</p>):(<></>)
                                }
                            </div>


                            <div className="field">
                                <label className="label">Repeat your chosen password</label>
                                <div className="form-input has-icons-left has-icons-right">
                                    <input className={`input ${passwordRepeatInvalid ? 'is-danger' : ''}`} type="password" placeholder="Repeat your desired password" value={this.state.form.passwordRepeat.value} onChange={this.handleInputChange} name="passwordRepeat"/>
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-lock"></i>
                                    </span>
                                </div>
                                {
                                    passwordRepeatInvalid ? (<p className="help is-danger">Your password is invalid. Make sure your password match and are longer than 8 characters.</p>):(<></>)
                                }
                            </div>

                            <div className="field">
                                <div className="form-input">
                                    <label className="checkbox">
                                    <input type="checkbox" name="conditions" onChange={this.handleInputChange} />
                                        &nbsp;I agree to the <a href="#">terms and conditions</a>
                                    </label>
                                </div>
                            </div>

                            <div className="field is-grouped">
                                <div className="form-input">
                                    {
                                        <button className="btn btn-link" disabled={isWorking || !formValid ? 'disabled' : ''}>
                                            {
                                                isWorking ? (
                                                    <>
                                                    <span>
                                                        <i className="fas fa-circle-notch fa-spin"></i>&nbsp;Creating your account. Please wait.
                                                    </span>
                                                    </>
                                                ) : (
                                                    <>
                                                    Create your account
                                                    </>
                                                )
                                            }
                                        </button>
                                    }
                                    
                                </div>
                            </div>                        
                        </div>
                    </form>
                </div>
            </section>
            </>
        )
    }

    step2() {
        return (
            <>
            <section className="section">
                <div className="container">
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                        <ul>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li className="is-active"><a href="#" aria-current="page">Sign up</a></li>
                        </ul>
                    </nav>

                    <h1 className="title"><i className="far fa-check-circle" style={{color: 'green'}}></i> Your account has been created.</h1>
                    <h2 className="subtitle">Email validation is required.</h2>

                    <div className="box">
                        <p>We have sent an e-mail to {this.state.form.email.value}.</p>
                        <p>Please follow the instructions in the e-mail to activate your account. The activation link will expire in 2 days.</p>
                        <br />
                        <p>If you can't find the activation e-mail make sure you also check your spam folder.</p> 
                        <p>
                            Once you have activated your account you will be able to <Link to="/login">log in</Link>.
                        </p>
                        <p>
                            <br />
                            <ul>
                                <li><a>Click here to re-send activation e-mail</a></li>
                                <li><a>Get help with your account here.</a></li>
                            </ul>
                        </p>
                    </div>
                </div>
            </section>
            </>
        );
    }

    render() {
        const step = this.state.step;

        if (step === 1) {
            return this.step1();
        } else if (step === 2) {
            return this.step2();
        }
    }
};