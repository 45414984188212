import React from 'react';

export default class AccountNotifications extends React.Component {
    render() {
        return (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md-12">
                            <h1 className="section-separator">Notifications</h1>
                            <p>Choose how you receive notifications.</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}