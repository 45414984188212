export const USER_INFO_UPDATE = "USER_INFO_UPDATE";
export const LOG_OUT = "LOG_OUT";
export const BAND_INFO_UPDATE = "BAND_INFO_UPDATE"
export const SET_MUSIC_TRACK = "SET_MUSIC_TRACK";

export const setUserInfo = userInfo => ({
    type: USER_INFO_UPDATE,
    userInfo
});

export const logOut = () => ({
    type: LOG_OUT
});

export const setBandInfo = bandInfo => ({
    type: BAND_INFO_UPDATE,
    bandInfo
});

export const setMusicTrack = track => ({
    type: SET_MUSIC_TRACK,
    track
});