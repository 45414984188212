import React, { useState, useEffect } from 'react';
import Columns from 'react-columns';
import apiService from '../../../services/apiservice';

import './fans.css';
import { Redirect } from 'react-router-dom';


export default function Fans(props) {

    const [fan, setFan] = useState({});
    const [fans, setFans] = useState([]);
    const queries = [{
        columns: 3,
        query: 'min-width: 500px'
    }, {
        columns: 5,
        query: 'min-width: 1000px'
    }];

    useEffect(() => {
        apiService.getFans().subscribe(rsp => {
            setFans(rsp);
        });
    });

    if (fan.id !== undefined) {
        return <Redirect to={`/profile/${fan.id}`} push={true} />
    }

    return (
        <>
            <section className="section-fans">
                <div className="gen-listings fans">
                    <div className="action-buttons">
                        <div className="control has-icons-left">
                            <input className="form-control is-small" type="email" placeholder="Search your fans" />
                        </div>
                    </div>


                    <Columns columns={5} gap="10px" queries={queries}>
                        {
                            fans.map((fan, i) => {
                                return <div className="fan" key={fan.id} onClick={() => setFan(fan)}>
                                    <div className="fan-picture">
                                        <img src={fan.profile} alt="profile picture" />
                                    </div>
                                    <div className="fan-name">
                                        {fan.name}
                                    </div>
                                    <div className="fan-info">
                                        Fan since Jan 21, 2018
                                    </div>
                                    <div className="fan-actions">
                                        <button className="btn btn-info is-light is-small is-fullwidth">View profile</button>
                                    </div>
                                </div>
                            })
                        }
                    </Columns>
                </div>
            </section>
        </>
    )
}