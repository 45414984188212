import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import apiService from './services/apiservice';
import { connect } from 'react-redux';
import { setUserInfo, setMusicTrack } from './reducers/actions';
import { ProtectedRoute } from './components/common/routes';
import { Header, Footer, Home, FourOhFour, Login, Artist, About, Discover, BandStudio, Genres, AppLoading, Account, Register } from './components';
import MusicPlayer from './components/common/musicplayer';
import Events from './components/events';
import 'mapbox-gl/dist/mapbox-gl.css';
import Music from './components/music';

class App extends React.Component {
    state = {
        isWorking: true
    };
    
    componentDidMount() {
        apiService.isLoggedIn().subscribe(rsp => {
            this.props.setUserInfo(rsp.payload);
            this.setState({
                isWorking: false
            });
        });
    }

    render() {
        let isWorking = this.state.isWorking;
        return (
            <>
                {
                    isWorking ? (
                        <AppLoading />
                    ) : (
                            <BrowserRouter>
                                <Header />
                                <div className="container-fluid">
                                    <Switch>
                                        <Route path="/" exact component={Home} />
                                        <Route path="/login" exact component={Login} />
                                        <Route path="/register" exact component={Register} />
                                        <Route path="/discover" exact component={Discover} />
                                        <Route path="/about" exact component={About} />
                                        <Route path="/u/:artistId" component={Artist} />
                                        <ProtectedRoute path="/account" component={Account} />
                                        <ProtectedRoute membersOnly={true} path="/band-studio" component={BandStudio} />
                                        <Route path="/genres" component={Genres} />
                                        <Route path="/events" component={Events} />
                                        <Route path="/music" component={Music} />
                                        <Route component={FourOhFour} />
                                    </Switch>
                                    <MusicPlayer />
                                </div>
                                <Footer/>
                            </BrowserRouter>
                        )
                }
            </>
        );
    }
}

export default connect(null, { setUserInfo, setMusicTrack })(App);
