import React, { useEffect, useState } from 'react';
import ApiService from '../../../services/apiservice';
import { useDispatch } from 'react-redux';
import { setMusicTrack } from '../../../reducers/actions';

import './playlist.css';

export default function Playlist(props) {
    const [playlist, setPlaylist] = useState();
    const [track, setTrack] = useState({});
    const dispatch = useDispatch();

    const playTrack = (t) => {
        setTrack(t);
        dispatch(setMusicTrack(t));
    };

    useEffect(() => {
        ApiService.getPlaylist(props.match.params.playlistId).subscribe((playlist) => {
            setPlaylist(playlist);
        });
    }, [props.match.params.playlistId]);

    if (playlist === undefined) {
        return null;
    }

    return (
        <div className="post">
            <div className="post-header d-flex">
                <h4 className="flex-grow-1">{playlist.name}</h4>
                <div className="post-header-right flex-shrink-1">
                    <i className="fas fa-compact-disc"></i>
                </div>
            </div>
            <div className="post-body">

                {
                    playlist.tracks.map((t, i) => {
                        return (
                            <div key={i} className="playlist-item d-flex" onClick={() => playTrack(t)}>
                                <div className="item-image flex-shrink-1 mr-2">
                                    <img className="img-fluid" src={t.album.coverImage} />
                                </div>
                                <div className="item-info d-flex flex-column flex-grow-1 justify-content-center">
                                    <h5 className="item-title">{t.name}</h5>
                                    <div className="item-album">{t.artist.name}</div>
                                </div>
                                <div className="item-album d-flex flex-column flex-grow-1 justify-content-center text-right">
                                    <h6 className="item-title">{t.album.name}</h6>
                                </div>
                                <div className="item-details d-flex flex-column flex-shrink-1 justify-content-center">
                                    <div className="item-duration">{t.duration}</div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}