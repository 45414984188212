import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class ProtectedRoute extends React.Component {
    constructor(props) {
        super(props);

        let {
            path,
            membersOnly,
            component
        } = props;

        membersOnly = membersOnly === undefined ? false : membersOnly;

        this.state = {
            path: path,
            component: component,
            membersOnly: membersOnly
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            path: props.path,
            component: props.component,
            membersOnly: props.membersOnly
        });
    }

    render() {
        if (this.props.userInfo.loggedIn) {
            if (this.state.membersOnly && this.props.userInfo.subscription.status === 'paid') {
                return (<Route path={ this.state.path } component={ this.state.component } />);
            } else if (this.state.membersOnly && this.props.userInfo.subscription.status !== 'paid') {
                return (<Redirect to="/account"></Redirect>);
            } else {
                return (<Route path={ this.state.path } component={ this.state.component } />);
            }
        } else {
            return (<Redirect to="/login"></Redirect>);
        }
    }
}

const mapUserInfo = (state) => {
    return {
        userInfo: state.userInfo
    }
};

export default connect(mapUserInfo)(ProtectedRoute);