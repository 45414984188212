import React from 'react';

import './home.css';

export default class Home extends React.Component {
    render() {
        return (
            <>
                <div id="section-index">
                    <div className="intro">
                        <div className="intro_bg shadow">
                            <h1>Respect your sound</h1>
                        </div>
                    </div>

                    <div className="container testimonials">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="testimonial d-flex flex-column">
                                    "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus."
                                    <div className="d-flex align-items-center testimonial-from">
                                        <img src="/img/account/me.jpg" className="mr-3" alt="..."/>
                                        <h5 className="mt-0">John Smith</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="testimonial d-flex flex-column">
                                    "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus."
                                    <div className="d-flex align-items-center testimonial-from">
                                        <img src="/img/account/me.jpg" className="mr-3" alt="..."/>
                                        <h5 className="mt-0">John Smith</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="testimonial d-flex flex-column">
                                    "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus."
                                    <div className="d-flex align-items-center testimonial-from">
                                        <img src="/img/account/me.jpg" className="mr-3" alt="..."/>
                                        <h5 className="mt-0">John Smith</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid benefits">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1 className="title text-center">Why join?</h1>
                                    <h2 className="subtitle">Join the largest community of artists sharing their music online. Bandrabbit helps creators share and promote their work on a simple subscription basis.</h2>
                                </div>
                            </div>
                            <div className="row">

                                <div className="col-md-4">
                                    <div className="benefit text-center">
                                        <div className="benefit-image">
                                            <img src="/img/home/why1.png" />
                                        </div>
                                        <div className="benefit-content">
                                            <h1>Lorem ipsum</h1>
                                            <p>gfd sgsfd gsfd gsf dgfd gdf gfsd ghsfdghre. lghzf gf fsdg sfdgsdf.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="benefit text-center">
                                        <div className="benefit-image">
                                            <img src="/img/home/why2.png" />
                                        </div>
                                        <div className="benefit-content">
                                            <h1>Lorem ipsum</h1>
                                            <p>gfd sgsfd gsfd gsf dgfd gdf gfsd ghsfdghre. lghzf gf fsdg sfdgsdf.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="benefit text-center">
                                        <div className="benefit-image">
                                            <img src="/img/home/why3.png" />
                                        </div>
                                        <div className="benefit-content">
                                            <h1>Lorem ipsum</h1>
                                            <p>gfd sgsfd gsfd gsf dgfd gdf gfsd ghsfdghre. lghzf gf fsdg sfdgsdf.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid join-now">
                        <div className="container text-center">
                            <h1>Join thousand of artists on Bandrabbit</h1>
                            <button className="btn btn-danger">Get started</button>
                        </div>
                    </div>
     
                    <div className="container-fluid how-it-works">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <h1 className="title">How it works?</h1>
                                    <h2 className="subtitle">Join the largest community of artists sharing their music online. Bandrabbit helps creators share and promote their work on a simple subscription basis.</h2>
                                </div>
                            </div>

                            <div className="row works-example">
                                <div className="col-md-6 works-image">
                                    <img src="/img/home/works1.jpg" alt="how it works" className="img-fluid" />
                                </div>

                                <div className="col-md-6">
                                    <h1 className="title">View your dashboard</h1>
                                    <p>Ut ac eros vitae lorem malesuada egestas eget id urna. Sed fringilla, lectus at aliquet sollicitudin, tellus diam euismod arcu, sed bibendum nisi odio facilisis tellus. Suspendisse vitae orci felis.</p>
                                </div>
                            </div>

                            <div className="row works-example">
                                <div className="col-md-6">
                                    <h1 className="title">Connect with your fans</h1>
                                    <p>Ut ac eros vitae lorem malesuada egestas eget id urna. Sed fringilla, lectus at aliquet sollicitudin, tellus diam euismod arcu, sed bibendum nisi odio facilisis tellus. Suspendisse vitae orci felis.</p>
                                </div>

                                <div className="col-md-6 works-image">
                                    <img src="/img/home/works2.png" alt="how it works" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    };
}