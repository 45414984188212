import React from 'react';
import { Route } from 'react-router-dom';
import Post from './post';
import PostListing from './listing/postlisting';

export default class Posts extends React.Component {
    render() {
        return (
            <>
                <Route exact path={this.props.match.url} component={PostListing} />
                <Route exact path={`${this.props.match.url}/:postId`} component={Post} />
            </>
        );
    }
}