import React from 'react';

export default class AlbumVideos extends React.Component {
    render() {
        return (
            <>
                Videos
            </>
        );
    }
}