import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Genre from './genre';
import GenreList from './genrelist';

export default class Genres extends React.Component {
    render() {
        return (
            <>
                <Switch>
                    <Route exact path={`${this.props.match.path}`} component={GenreList} />
                    <Route exact path={`${this.props.match.path}/:genreId`} component={Genre} />
                </Switch>
            </>
        );
    }
}