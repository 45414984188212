import React, { useEffect, useState } from 'react';
import apiService from '../../../../services/apiservice';

import './messagelisting.css';
import { Redirect } from 'react-router-dom';

export default function MessageListing(props) {
    let [messages, setMessages] = useState([]);
    let [message, setMessage] = useState({});

    useEffect(() => {
        apiService.getMessages().subscribe((rsp) => {
            setMessages(rsp);
        });
    }, []);

    if (message.id !== undefined) {
        return <Redirect to={`${props.match.url}/${message.id}`} push={true} />
    }

    return <>
        <div className="gen-listings">
            <div className="action-buttons">
                <button className="btn btn-info btn-sm mr-1"><i className="far fa-paper-plane"></i>&nbsp;Compose message</button>
                <button className="btn btn-sm btn-outline-secondary mr-1">
                    <i className="fas fa-sync"></i>&nbsp;Refresh
                </button>
                <button className="btn btn-sm btn-outline-secondary mr-1">
                    <i className="far fa-envelope"></i>&nbsp;Mark read
                </button>
                <button className="btn btn-danger btn-sm mr-1">
                        <i className="far fa-trash-alt"></i>&nbsp;Delete
                </button>
            </div>

            {
                messages.map((msg, i) => {
                    return (
                        <div key={msg.id} className={`d-flex gen-listing ${msg.state === 'unread' ? 'is-unread' : ''} ${msg.isSelected ? 'is-selected' : ''}`} onClick={(e) => setMessage(msg)}>
                            <div className="gen-listing-selector flex-shrink-1">
                                <label className="checkbox">
                                    <input type="checkbox" />
                                </label>
                            </div>
                            <div className="gen-listing-icons flex-shrink-1">
                                <i className="far fa-envelope"></i>
                            </div>
                            <div className="gen-listing-from flex-shrink-1">
                                {msg.from}
                            </div>
                            <div className="gen-listing-title flex-grow-1">
                                {msg.title}
                            </div>

                            <div className="gen-listing-date flex-shrink-1">
                                {msg.date}
                            </div>
                        </div>
                    )
                })
            }

            <nav aria-label="Page navigation example">
                <ul className="pagination">
                    <li className="page-item disabled">
                    <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">1</a></li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item">
                    <a className="page-link" href="#">Next</a>
                    </li>
                </ul>
            </nav>
        </div>
    </>
}