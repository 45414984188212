import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './header.css';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { logOut } from '../../reducers/actions';


function Header(props) {
    const isLoggedIn = props.userInfo.loggedIn;
    const isPaid = props.userInfo.subscription.status === 'paid';
    const location = useLocation();

    return (
        <>
            <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <Link to="/" className="navbar-brand">
                        <img src="/img/common/logo_inverted.png" height="40" alt="" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-4 mr-auto">
                            <li className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
                                <Link to="/" className="nav-link">Home</Link>
                            </li>
                            <li className={`nav-item ${location.pathname.startsWith('/discover') ? 'active' : ''}`}>
                                <Link to="/discover" className="nav-link">Discover</Link>
                            </li>
                            <li className={`nav-item dropdown ${location.pathname.startsWith('/genres') ? 'active' : ''}`}>
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Genres
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">

                                    <Link to="/genres/pop" className="dropdown-item">Pop</Link>
                                    <Link to="/genres/rock" className="dropdown-item">Rock</Link>
                                    <Link to="/genres/metal" className="dropdown-item">Metal</Link>

                                <div className="dropdown-divider"></div>
                                    <Link to="/genres" className="dropdown-item">More genres</Link>
                                </div>
                            </li>
                            <li className={`nav-item ${location.pathname.startsWith('/events') ? 'active' : ''}`}>
                                <Link to="/events" className="nav-link">Events</Link>
                            </li>
                            <li className={`nav-item ${location.pathname.startsWith('/music') ? 'active' : ''}`}>
                                <Link to="/music" className="nav-link">Music Player<span className="badge badge-info ml-1">beta</span></Link>
                            </li>
                            <li className={`nav-item ${location.pathname.startsWith('/about') ? 'active' : ''}`}>
                                <Link to="/about" className="nav-link">About</Link>
                            </li>
                        </ul>
                        <form class="form-inline my-2 my-lg-0">
                            {
                                isLoggedIn ? (
                                    <ul className="my-account">
                                        <li className="nav-item dropdown">
                                            <a className="nav-link btn-fair dropdown-toggle d-flex align-items-center" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src="/img/account/me.jpg" alt="me" className="avatar mr-1" />{props.userInfo.firstName} {props.userInfo.lastName}
                                            </a>
                                            <div className="dropdown-menu form-inline" aria-labelledby="navbarDropdown">

                                                <Link to="/account" className="dropdown-item">My account</Link>
                                                {
                                                    isPaid ? (
                                                        <NavLink to="/band-studio" className="dropdown-item">Band studio</NavLink>
                                                    ) : (<></>)
                                                }

                                                <div className="dropdown-divider"></div>
                                                    <a className="dropdown-item" onClick={props.logOut}>
                                                        Sign out
                                                    </a>
                                                </div>
                                        </li>
                                    </ul>
                                ) : (
                                        <>
                                            <div className="navbar-item">
                                                <div className="buttons">
                                                    <Link className="btn btn-sm btn-primary mr-1" to="/register">Register</Link>
                                                    <Link className="btn btn-sm btn-light" to="/login">Log in</Link>
                                                </div>
                                            </div>
                                        </>
                                )
                            }
                        </form>
                    </div>
                </div>
            </nav>
        </>
    )
}

const mapUserInfo = (state) => {
    return {
        userInfo: Object.assign({}, state.userInfo)
    };
};

export default connect(mapUserInfo, { logOut })(Header);