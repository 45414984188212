import React from 'react';

export default class Albums extends React.Component {
    render() {
        return (
            <>
                Album list
            </>
        );
    }
}