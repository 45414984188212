import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import './calendar.css';

export default function Calendar(props) {
    const localProps = Object.assign({}, props);

    const summary = (text, words=20) => {
        return text.split(' ').splice(0, words).join(' ') + '...';
    };

    const daysInMonth = (month, year) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const getDay = (date) => {
        let day = date.getDay() - 1;
        if (day === -2) {
            day = 5
        } else if (day === -1) {
            day = 6
        }

        return day;
    }

    const selectEvent = (event) => {
        if (localProps.onSelectEvent !== undefined) {
            localProps.onSelectEvent(event);
        }
    };

    const onDateChanged = (date) => {
        setCurrentDate(date);
        localProps.onDateChanged(date);
    };

    const [currentDate, setCurrentDate] = useState(localProps.date);
    const [events, setEvents] = useState(localProps.events);

    useEffect(() => {
        if (localProps.date === undefined) {
            setCurrentDate(new Date());
        } else {
            setCurrentDate(localProps.date);
        }

        if (localProps.events === undefined) {
            setEvents([]);
        } else {
            setEvents(localProps.events);
        }
    }, [localProps.date, localProps.events]);

    if (currentDate === undefined) {
        return null;
    }

    const monthStart = 1;
    const monthEnd = daysInMonth(currentDate.getMonth(), currentDate.getYear());
    
    const monthStartOffset = getDay(new Date(currentDate.getFullYear(), currentDate.getMonth()));
    const previousMonthEnd = daysInMonth(currentDate.getMonth() - 1, currentDate.getYear());
    const previousMonthStart = previousMonthEnd - monthStartOffset + 1;
    
    const month = [];

    for(let i=previousMonthStart;i<=previousMonthEnd;i++) {
        const previousMonth = currentDate.getMonth() - 1;
        let previousYear = currentDate.getFullYear();

        if (previousMonthEnd == 12) {
            previousYear--;
        }

        month.push({
            "type": "prev",
            "num": i,
            "date": new Date(previousYear, previousMonth, i),
            "curr": false
        });
    }

    for(let i=monthStart;i<=monthEnd;i++) {
        month.push({
            "type": "curr",
            "num": i,
            "date": new Date(currentDate.getFullYear(), currentDate.getMonth(), i),
            "curr": currentDate.getDate() === i
        });
    }

    let startSlice = 0;
    let endSlice = startSlice + 7;
    const weeks = [];

    let rows = 5;{
        rows++;
    }

    for (let i=1;i<=rows;i++) {
        let monthSlice = month.slice(startSlice, endSlice);
        if (!monthSlice.length) {
            break;
        }

        let diffDays = 7 - monthSlice.length;
        
        if (diffDays !== 0) {
            for(let i=1;i<=diffDays;i++) {

                const nextMonth = currentDate.getMonth() + 1;
                let nextYear = currentDate.getFullYear();

                if(nextMonth == 1) {
                    nextYear++;
                }

                monthSlice.push({
                    "type": "next",
                    "num": i,
                    "date": new Date(nextYear, nextMonth, i),
                    "curr": false
                });
            }
        }

        weeks.push(monthSlice);
        startSlice = endSlice ;
        endSlice = startSlice + 7;
    }

    if (currentDate === undefined) {
        return null;
    }

    return (
        <>
            <div className="calendar">
                <div className="calendar-header d-flex">
                    <div className="calendar-day text-center">Mon</div>
                    <div className="calendar-day text-center">Tue</div>
                    <div className="calendar-day text-center">Wed</div>
                    <div className="calendar-day text-center">Thu</div>
                    <div className="calendar-day text-center">Fri</div>
                    <div className="calendar-day text-center">Sat</div>
                    <div className="calendar-day text-center">Sun</div>
                </div>
                <div className="calendar-body">

                    {
                        weeks.map((week, i) => {
                            return (
                                <div key={i} className="calendar-week d-flex">
                                    {
                                        week.map((day, j) => {
                                            return <div key={j} className="calendar-day text-center">
                                                <a className={`${day.type !== 'curr' ? 'gray': 'curr'} ${day.curr ? 'current-day' : ''}`} onClick={() => onDateChanged(day.date)}>{day.num}</a>
                                            </div>
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {
                    events.length !== 0 ? (
                        <div className="calendar-events">
                            <div className="calendar-event">
                                <div className="event-date">
                                    {
                                        format(currentDate, "LLLL dd, yyyy")
                                    }
                                </div>

                                {
                                    events.map((event, i) => {
                                        return <div key={i} className="event-info d-flex" onClick={() => selectEvent(event)}>
                                            <div className="event-time mr-2 d-flex flex-column">
                                                <div className="event-hours">{format(new Date(event.datetime), "h:mm")}</div>
                                                <div className="event-ampm">{format(new Date(event.datetime), "a")}</div>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="event-title">{event.name}</div>
                                                <div className="event-desc">{summary(event.description, 10)}</div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    ) : (null)
                }
            </div>
        </>
    )
}